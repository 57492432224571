import { useMemo, useState } from 'react';
import clsx from 'clsx';

// Images
import eyeOpen from 'images/eye.svg';
import eyeClosed from 'images/eye-closed.svg';

const PasswordInput = ({
    label = "Input",
    error = "",
    className,
    isRequired = false,
    ...props
}) => {

    const [showPassword, setShowPassword] = useState(false)

    const inputClass = clsx('w-full p-3 pr-10 rounded bg-[#0D1B2A] border border-[#382F5C]', className)

    const inputId = useMemo(() => {
        if (props.id) {
            return props.id;
        }
        return `text-input-${Math.random().toString(36).substring(2, 9)}`;
    }, [props?.id])

    return (
        <div className="relative flex flex-col gap-1">
            {
                label && (
                    <label htmlFor={inputId} className="block text-sm text-left">
                        <span>
                            {label}
                        </span>

                        {
                            isRequired && <span className={`text-red-500 ml-[1px] text-base`}> *</span>
                        }
                    </label>
                )
            }

            <input
                type={showPassword ? "text" : "password"}
                autoComplete={"off"}
                id={inputId}
                className={inputClass}
                {...props}
            />

            <img
                src={showPassword ? eyeOpen : eyeClosed}
                alt="Toggle visibility"
                className="absolute h-5 right-3 top-10 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
            />
            <span className="text-red-500 text-left text-sm">{error}</span>
        </div>
    )
}

export default PasswordInput;