import * as yup from "yup";
import { emailSchema } from 'validations/common';

export const CreateDashboardSchema = yup.object().shape({
    dashboard_name: yup.string().trim().required("Please enter dashboard name"),
});

export const CreateDashboardAlertSchema = yup.object().shape({
    chart_id: yup.number().required("Please select a chart"),
    value_column: yup.string().required("Please select a column"),
    condition: yup.string().required("Please select a condition"),
    threshold: yup.string().required("Please input the threshold"),
    email: emailSchema,
});