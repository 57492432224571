import React, { useEffect, useState } from "react";
import logo from "images/main-logo.svg";
import eyeOpen from "images/eye.svg";
import eyeClosed from "images/eye-closed.svg";
import { notifyError } from "utils/customToasts";
import { changePassword } from "api/auth/index";
import { useNavigate } from "react-router";

const ChangePasswordComponent = () => {
	const [currentPassword, setCurrentPassword] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [showPassword, setShowPassword] = useState("");
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	
	const [isPasswordChanged, setIsPasswordChanged] = useState(false);
	const [errors, setErrors] = useState({})

	const navigate = useNavigate();

	const isFormValid = () => {
		return currentPassword && password && confirmPassword;
	};

	const getValidPayload = async () => {
		// Handle Empty Fields
		if (!isFormValid()) {
			const errorObj = {};
			if (!currentPassword) {
				errorObj.currentPassword = "Please enter temporary password";
			}

			if (!password) {
				errorObj.password = "Please enter new password";
			}

			if (!confirmPassword) {
				errorObj.confirmPassword = "Please confirm new password";
			}

			setErrors(prev => ({ ...prev, ...errorObj }))
			return null;
		}

		// Check for same password
		if (currentPassword === password) {
			setErrors(prev => ({ ...prev, password: "new password must be different from old password" }));
			return null;
		}

		// Confirm new password
		if (password !== confirmPassword) {
			setErrors(prev => ({ ...prev, confirmPassword: "Confirm password does not match with new password" }));
			return null;
		}

		// Reset errors
		setErrors({});

		const payload = { currentPassword, password };
		return payload;
	};

	const passwordChangeRequest = async (e) => {
		e.preventDefault();
		try {
			const payload = await getValidPayload();

			if (!payload) {
				return notifyError("Please fix the errors");
			}

			const response = await changePassword(payload);

			if (response?.statusCode !== "10000") {
				setErrors(response?.fieldErrors || {});
				return notifyError(response?.message || "Failed to change password");
			}

			setIsPasswordChanged(true);

		} catch (err) {
			console.error("Password Change Error: ", err)
			notifyError("An error occurred while changing password. Please try again later.");
		}
	}

	useEffect(() => {
		if (isPasswordChanged) {
			setTimeout(() => { navigate("/chat") }, 3000)
		}
	}, [isPasswordChanged])

	return (
		<div className="mt-10 flex items-center justify-center bg-[#0D1B2A]">
			<div className={`${isPasswordChanged ? "" : "bg-[#0B1723]"} text-white p-8 rounded-lg w-full max-w-md`}>
				<img src={logo} alt="Storymetrix Logo" className="h-16 mx-auto mb-8" />

				{
					isPasswordChanged ? (
						<>
							<p className={`text-center text-green-300 font-bold mt-52 mb-6 transition-opacity duration-300 ease-out`}>
								Password successfully changed!
							</p>
						</>
					) : (
						<>
							<h2 className={`text-2xl font-bold text-center mb-4 transition-opacity duration-300 ease-out`}>
								Change your password
							</h2>

							<div className={`transition-opacity text-left mt-10 duration-300 ease-out`}>

								<div className="mb-4 relative">
									<label htmlFor="old-password" className="block mb-2 text-sm">Current password</label>
									<input
										type={showCurrentPassword ? 'text' : 'password'}
										id="old-password"
										value={currentPassword}
										onChange={(e) => setCurrentPassword(e.target.value)}
										className="w-full p-3 rounded bg-[#0D1B2A] border border-[#382F5C]"
										placeholder="Enter Current password"
									/>
									<img
										src={showCurrentPassword ? eyeOpen : eyeClosed}
										alt="Toggle visibility"
										className="absolute h-5 right-3 top-10 cursor-pointer"
										onClick={() => setShowCurrentPassword(!showCurrentPassword)}
									/>

									<span className="text-red-500 mt-2">{errors?.currentPassword}</span>
								</div>

								<div className="mb-4 relative">
									<label htmlFor="password" className="block mb-2 text-sm">New password</label>
									<input
										type={showPassword ? 'text' : 'password'}
										id="password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										className="w-full p-3 rounded bg-[#0D1B2A] border border-[#382F5C]"
										placeholder="Enter your new password"
									/>
									<img
										src={showPassword ? eyeOpen : eyeClosed}
										alt="Toggle visibility"
										className="absolute h-5 right-3 top-10 cursor-pointer"
										onClick={() => setShowPassword(!showPassword)}
									/>

									<span className="text-red-500 mt-2">{errors?.password}</span>
								</div>

								<div className="mb-4 relative">
									<label htmlFor="confirm-password" className="block mb-2 text-sm">Confirm password</label>
									<input
										type={showConfirmPassword ? 'text' : 'password'}
										id="confirm-password"
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value)}
										className="w-full p-3 rounded bg-[#0D1B2A] border border-[#382F5C]"
										placeholder="Re-enter new password"
									/>
									<img
										src={showConfirmPassword ? eyeOpen : eyeClosed}
										alt="Toggle visibility"
										className="absolute h-5 right-3 top-10 cursor-pointer"
										onClick={() => setShowConfirmPassword(!showConfirmPassword)}
									/>

									<span className="text-red-500 mt-2">{errors?.confirmPassword}</span>
								</div>

								<button
									className={`w-full uppercase p-3 mt-4 rounded transform transition-transform duration-300 ease-out ${isFormValid() ? 'bg-purple-700 hover:bg-purple-800' : 'bg-gray-600 cursor-not-allowed'}`}
									disabled={!isFormValid()}
									onClick={passwordChangeRequest}
								>
									Change password
								</button>
							</div>
						</>
					)
				}
			</div>
		</div>
	);
};

export default ChangePasswordComponent;