const ChatActionButton = ({
    onClick,
    icon = "",
    label = "Button"
}) => {
    
    const clickHandler = () => {
        if (typeof onClick === "function") {
            onClick(icon);
        }
    }

    return (
        <div className="flex items-center" onClick={clickHandler}>
            <img
                src={icon}
                alt="Change Value"
                className={`h-6 w-6`}
            />
            {label}
        </div>
    )
}

export default ChatActionButton;