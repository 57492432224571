import Modal from "components/Modal";
import { useState } from "react";

// Images
import ArrowLeftIcon from "images/arrow-left-icon.svg";
import loadingGif from 'images/loader2.webp';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedGraph, setShareCollabrationVisibility } from 'slices/collabration/reducer';
import CheckIcon from 'images/check-square-icon.svg';
import UncheckIcon from 'images/uncheck-square-icon.svg';
import UserSelect from "components/Dropdown/UserSelect";
import { AddCollabrationSchema } from '../../validations/collabration';
import { addForumEntry } from '../../api/collaborativeForum/index';
import { notifySuccess, notifyError } from "utils/customToasts";
import { logEvent } from 'utils/analytics';

const ShareWithCheckbox = (props) => {
    const { children, ...restProps } = props
    return (
        <div className="py-1 flex items-center gap-2">
            <div className="relative">
                <input
                    type="checkbox"
                    {...restProps}
                    className={`absolute cursor-pointer opacity-0 w-full h-full`}
                />
                <img
                    src={props.checked ? CheckIcon : UncheckIcon}
                    alt={props.checked ? "Deselect" : "Select"}
                    className="cursor-pointer"
                />
            </div>
            <span>{children}</span>
        </div>
    )
}

const shareWithOption = [
    {
        title: "Graph",
        value: "Graph",
    },
    {
        title: "Summary",
        value: "Summary",
    },
    {
        title: "Recommended action",
        value: "Recommended action",
    },
    {
        title: "All",
        value: "All",
    }
]

const CreateCollabrationModal = ({ onClose }) => {

    const dispatch = useDispatch();

    // Status States
    const [loading, setLoading] = useState(false);
    // form States
    const [users, setUsers] = useState([]);
    const [options, setOptions] = useState([]);
    const [question, setQuestion] = useState("");
    const [errors, setErrors] = useState({ errorCount: 0 });
    // Store states
    const { selectedGraph, shareCollabrationVisibility } = useSelector(state => state.CollabrationReducer);

    // Handle Checkbox Select
    const handleOptionSelect = (event) => {
        const isChecked = event.target.checked;
        const value = event.target.value;

        setOptions(prev => {
            if (value === "All") {
                return isChecked ? shareWithOption.map(x => x.value) : [];
            }

            const currentOptions = [...prev];

            if (isChecked) {
                currentOptions.push(value);
            } else {
                currentOptions.splice(currentOptions.indexOf(value), 1);
            }

            return currentOptions;
        })
    }

    const verifyFormData = (payload) => {
        try {
            return AddCollabrationSchema.validate(payload, { abortEarly: false });
        } catch (err) {
            console.log("Dashboard Validation Error: ", err);
            throw new Error(err)
        }
    }

    // Handle share graph
    const shareGraph = async () => {
        try {
            const payload = {
                user_id: 1,
                query_id: selectedGraph.query_id,
                question: question,
                shared_parameters: options,
                shared_ids: users.map(x => x.user_id)
            }

            const verifyPayload = await verifyFormData(payload);

            const response = await addForumEntry(verifyPayload);

            if (!response?.message) {
                throw new Error(response.message)
            }

            // Log the click event to analytics
            logEvent("click", "ChatAction", "Thread", "add thread");

            notifySuccess(response?.message);
            handleModalClose();
        } catch (err) {
            let errorCount = 0
            const errObj = {};
            err.inner.forEach(error => {
                errObj[error.path] = error.message;
                errorCount += 1
            })
            setErrors({ ...errObj, errorCount });

            if (errorCount === 0) {
                notifyError(err?.message || "Something went wrong");
            }
        }
    }

    // Handle Modal close
    const handleModalClose = () => {
        dispatch(setSelectedGraph(null));
        dispatch(setShareCollabrationVisibility(false));
        if (onClose) {
            onClose();
        }
    }

    const handleUserSelect = (selectedData) => {
        setUsers(selectedData);
    }

    return (
        <Modal
            open={shareCollabrationVisibility}
            className="relative mx-auto max-w-[42rem] w-full px-8 py-10 bg-primary-3 text-white rounded-lg"
        >
            {/* Header */}
            <h3 className="flex items-center gap-4 text-xl">
                <img
                    onClick={handleModalClose}
                    className="cursor-pointer h-6 w-6"
                    alt={"Back"}
                    src={ArrowLeftIcon}
                />
                <span>Add questions</span>
            </h3>
            <hr className="mt-2 border border-gray-400" />

            <div className={`mt-6 flex flex-col gap-9`}>
                <div className="flex flex-col gap-1">
                    <textarea
                        rows={5}
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        className={`w-full resize-none bg-transparent rounded-lg border-gray-400 focus:border-white`}
                        placeholder="Add your question here...."
                    />
                    {
                        errors?.question && (
                            <span className="text-red-500">{errors?.question}</span>
                        )
                    }
                </div>


                <div className="flex flex-col gap-4">
                    <span>Share with</span>
                    <div className="flex flex-col gap-2">
                        {
                            shareWithOption?.map(option => {
                                return (
                                    <ShareWithCheckbox
                                        onChange={handleOptionSelect}
                                        key={option.value}
                                        checked={options.includes(option.value)}
                                        value={option.value}
                                    >
                                        {option.title}
                                    </ShareWithCheckbox>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="flex flex-col gap-1">
                    <UserSelect
                        selectedOption={users}
                        onChange={handleUserSelect}
                    />
                    {
                        errors?.shared_ids && (
                            <span className="text-red-500">{errors?.shared_ids}</span>
                        )
                    }
                </div>

            </div>

            <div className="flex justify-end mt-6 gap-4">
                <button
                    disabled={loading}
                    className="py-2 px-4 border border-gray-200 disabled:border-gray-300 rounded text-gray-300 disabled:text-gray-300"
                    onClick={handleModalClose}
                >
                    Cancel
                </button>

                <button
                    disabled={loading}
                    className="py-2 bg-primary-1 px-4 rounded text-white flex gap-2 items-center disabled:bg-gray-600 disabled:text-gray-300"
                    onClick={shareGraph}
                >
                    {
                        loading && (
                            <img src={loadingGif} alt="loading" className={`h-4 w-4`} />
                        )
                    }
                    <span>
                        {loading ? "Submiting" : "Submit"}
                    </span>
                </button>
            </div>
        </Modal>
    )
}

export default CreateCollabrationModal;