import React, { useState, useEffect, useRef } from 'react';
import styles from 'styles/headerAlt.module.css';
import Select from 'react-select';

// Images
import graphIcon from 'images/dialogue/graph.svg';

const selectStyles = {
    control: (provided) => ({
        ...provided,
        opacity: 0,
        cursor: "pointer"
    }),
    menu: (provided) => ({
        ...provided,
        color: "white",
        maxWidth: "300px",
        width: "90vw",
        borderRadius: 10,
        overflow: "hidden",
        boxShadow: "0px 0px 4px 0px #7357EB40",
        backgroundColor: "#0B1723",
    }),
    option: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#0B1723",
        ":hover": {
            backgroundColor: "#132639",
        }
    })
};

const ForecastButton = ({
    selectedOption,
    onChange,
    options
}) => {
    return (
        <div className='relative w-full flex items-center justify-center cursor-pointer'>
            <div className={`absolute flex gap-2`}>
                <img
                    src={graphIcon}
                    alt="Forecast"
                    className={styles.optionIcon}
                    title="click for forecast"
                />
                FORECAST
            </div>

            <Select
                className={`absolute`}
                isSearchable={false}
                options={options}
                value={selectedOption}
                backspaceRemovesValue={false}
                components={{ DropdownIndicator: null, IndicatorSeparator: null }}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                onChange={(newValue) => {
                    onChange(newValue);
                }}
                // menuPosition={"absolute"}
                menuPlacement={"auto"}
                styles={selectStyles}
                tabSelectsValue={false}
                maxMenuHeight={250}
            />

        </div>
    );
};

export default ForecastButton;
