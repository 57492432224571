import Axios from "api/Axios/authAxios";

// ! Deprecated
// export const register = async (payload) => {
//     const response = await Axios.post(`/auth/register`, payload);
//     return response.data;
// }

// Onboard Tenant registration
export const onboardTenant = async (payload) => {
    const response = await Axios.post(`/tenant/onboard`, payload);
    return response.data;
}

// Login
export const login = async (payload) => {
    const response = await Axios.post(`/login`, payload);
    return response.data;
}

export const logout = async (payload) => {
    const response = await Axios.post(`/auth/logout`, payload);
    return response.data;
}

export const getProfileInfo = async () => {
    const response = await Axios.get(`/`);
    return response.data;
}

export const forgotPassword = async (payload) => {
    const response = await Axios.post(`/forgot-password-send-temp-password`, payload);
    return response.data;
}

export const changePassword = async (payload) => {
    const response = await Axios.post(`/change-password`, payload);
    return response.data;
}
