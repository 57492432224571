import Select from "react-select";

const selectStyles = {
    control: (provided) => ({
        ...provided,
        cursor: "pointer",
        backgroundColor: "#0B1723",
        color: "white",
        border: "1px solid #382F5C",
        padding: "6px 12px 6px 2px",
        outline: "none",
        textAlign: "left",

        ":hover": {
            border: "1px solid #666699",
            outline: "none",
        },

        ":focus": {
            border: "1px solid #666699",
            outline: "none",
        },

        ":active": {
            border: "1px solid #666699",
            outline: "none",
        },

    }),
    menu: (provided) => ({
        ...provided,
        color: "white",
        borderRadius: 10,
        overflow: "hidden",
        boxShadow: "0px 0px 4px 0px #7357EB40",
        backgroundColor: "#0B1723",
    }),
    option: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#0B1723",
        ":hover": {
            backgroundColor: "#132639",
        }
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: "white",
    }),
    multiValue: (baseStyles) => ({
        ...baseStyles,
        alignItems: "center",
        gap: "4px",
        backgroundColor: "#0D1B2A",
        color: "#ffffff",
        border: "1px solid #382F5C",
        borderRadius: "4px",
        padding: "6px",
    }),
    multiValueLabel: () => ({
        color: "#ffffff",
    }),
    multiValueRemove: () => ({}),
    input: (baseStyles) => ({
        ...baseStyles,
        color: "white",
    })
};


const MultiSelect = ({
    selectedOption,
    onChange,
    disabled,
    options,
    placeholder = "Select..."
}) => {

    return (
        <Select
            placeholder={placeholder}
            isMulti
            disabled={disabled}
            options={options}
            value={selectedOption}
            onChange={(newValue) => {
                onChange(newValue);
            }}
            backspaceRemovesValue={false}
            components={{
                IndicatorSeparator: null,
            }}
            menuPosition={"absolute"}
            menuPlacement={"auto"}
            styles={selectStyles}
            tabSelectsValue={false}
            closeMenuOnSelect={false}
        />
    );
};

export default MultiSelect;
