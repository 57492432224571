import Select from "react-select";
import MultiSelect from "./MultiSelect";

const selectStyles = {
    control: (provided) => ({
        ...provided,
        cursor: "pointer",
        backgroundColor: "#0B1723",
        color: "white",
        border: "1px solid #382F5C",
        padding: "6px 12px 6px 2px",
        outline: "none",
        textAlign: "left",

        ":hover": {
            border: "1px solid #666699",
            outline: "none",
        },

        ":focus": {
            border: "1px solid #666699",
            outline: "none",
        },

        ":active": {
            border: "1px solid #666699",
            outline: "none",
        },

    }),
    menu: (provided) => ({
        ...provided,
        color: "white",
        borderRadius: 10,
        overflow: "hidden",
        boxShadow: "0px 0px 4px 0px #7357EB40",
        backgroundColor: "#0B1723",
    }),
    option: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#0B1723",
        ":hover": {
            backgroundColor: "#132639",
        }
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: "white",
    }),
    multiValue: (baseStyles) => ({
        ...baseStyles,
        alignItems: "center",
        gap: "4px",
        backgroundColor: "#0D1B2A",
        color: "#ffffff",
        border: "1px solid #382F5C",
        borderRadius: "4px",
        padding: "6px",
    }),
    multiValueLabel: () => ({
        color: "#ffffff",
    }),
    multiValueRemove: () => ({}),
    input: (baseStyles) => ({
        ...baseStyles,
        color: "white",
    })
};


const WeekdaySelect = ({
    selectedOption,
    onChange,
    disabled
}) => {


    const options = [
        {
            label: "Monday",
            value: "Monday",
        },
        {
            label: "Tuesday",
            value: "Tuesday",
        },
        {
            label: "Wednesday",
            value: "Wednesday",
        },
        {
            label: "Thursday",
            value: "Thursday",
        },
        {
            label: "Friday",
            value: "Friday",
        },
        {
            label: "Saturday",
            value: "Saturday",
        },
        {
            label: "Sunday",
            value: "Sunday",
        }
    ];

    return (
        <MultiSelect
            placeholder={`Select weekdays...`}
            disabled={disabled}
            options={options}
            selectedOption={selectedOption}
            onChange={(newValue) => {
                onChange(newValue);
            }}
        />
    );
};

export default WeekdaySelect;
