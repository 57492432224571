import Modal from "components/Modal";
import { useEffect, useState } from "react";
import loadingGif from "images/loader2.webp";
import TextInput from "components/Base/TextInput";
import { notifySuccess, notifyError } from "utils/customToasts";
import { onboardTenant } from "api/auth";
import { useNavigate } from "react-router";
import { employeeSchema } from "validations/user";
import PasswordInput from "components/Base/PasswordInput";
import { onboardEmployee, updateEmployee } from "api/admin/index";
import PhoneInput from "components/Base/PhoneInput";
import UserRoleSelect from "components/UserRole/SelectRole";
import AllowedDataSources from "./AllowedDataSources";
import { useAuth } from 'context/AuthContext';
// import PhoneInput from "components/Base/PhoneInput";

const defaultFormData = {
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    contactNumber: "",
    countryCode: "",
    password: "",
    userRole: null,
    isActive: true
}

const ManageEmployeeDialog = ({ isEdit = false, open, onClose, data, onSave }) => {

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(defaultFormData);
    const [errors, setErrors] = useState({});
    const [step, setStep] = useState(0);
    const [selectedTables, setSelectedTables] = useState([]);

    const { user } = useAuth();

    // Handle field change
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked
            });
        } else if (name.includes("addressInfo") || name.includes("userDetail") || name.includes("employeeConfigs")) {

            if (name === "employeeConfigs.employeeIdPrefix" && value?.length > 3) {
                return;
            }

            const [parentKey, childKey] = name.split(".");
            setFormData({
                ...formData,
                [parentKey]: {
                    ...formData[parentKey],
                    [childKey]: value
                }
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    // Handle form validation
    const validateForm = async () => {
        try {
            await employeeSchema.validate({ ...formData, isEdit }, { abortEarly: false });
            return true;
        } catch (err) {
            const newErrors = err.inner.reduce((acc, error) => {
                acc[error.path] = error.message;
                return acc;
            }, {});
            console.log(newErrors)
            setErrors(newErrors);
            return false;
        }
    };

    // Validation for specific field
    const validateField = async (name, value) => {
        try {
            console.log("Check Names => ", { name, value });
            await employeeSchema.validateAt(name, { [name]: value, isEdit });
            setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
        } catch (err) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: err.message }));
            console.log("Check Names => ", { name, value, err: err?.message });
        }
    };

    // Handle blur event for input fields
    const handleBlur = (e) => {
        const { name, value } = e.target;
        validateField(name, value);  // Validate the field on blur
    };

    const createUser = async (data) => {
        try {
            const response = await onboardEmployee(data);
            if (response?.statusCode !== "10000") {
                setLoading(false);
                notifyError(response?.message || "onboarding failed");
                return;
            }

            if (typeof onSave === "function") {
                onSave(response.data)
            }
            setLoading(false);
            notifySuccess(response?.message || 'New credentials created!');
            resetModalStates();
        } catch (error) {
            setLoading(false);
            notifyError('Something went wrong during submission');
        }
    }

    const updateUser = async (data) => {
        try {
            // Craft the payload
            const allowedKeys = ["firstName", "lastName", "countryCode", "contactNumber", "employeeId", "employeeDetails", "userAccessibleTables"];
            const newPayload = {};
            Object.keys(data).forEach(key => {
                if (allowedKeys.includes(key)) {
                    newPayload[key] = data[key];
                }
            });

            const response = await updateEmployee(newPayload);

            if (response?.statusCode !== "10000") {
                setLoading(false);
                notifyError(response?.message || "Update failed");
                return;
            }
            if (typeof onSave === "function") {
                onSave(response.data)
            }
            setLoading(false);
            notifySuccess(response?.message || 'Profile Updated!');
            resetModalStates();
        } catch (error) {
            console.log("Error", error)
            setLoading(false);
            notifyError('Something went wrong during submission');
        }
    }

    // Submit onboard form
    const handleSubmitForm = async () => {
        setLoading(true);
        const isValid = await validateForm();

        if (!isValid) {
            setLoading(false);
            return;
        }

        const { userRole, ...restData } = formData;

        const finalPayload = {
            ...restData,
            userAccessibleTables: selectedTables.map(x => x.value),
            employeeDetails: {
                roleId: userRole.value,
            }
        }

        if (isEdit) {
            return await updateUser(finalPayload);
        }

        return await createUser(finalPayload);
    };

    // Handle Next Step
    const handleNextStep = async () => {
        const isValid = await validateForm();

        if (!isValid) {
            return;
        }

        return setStep(1);
    }

    // Reset States on Close
    const resetModalStates = () => {
        onClose();
        setErrors({});
        setFormData(defaultFormData);
        setLoading(false);
        setStep(0);
        setSelectedTables([]);
    }

    useEffect(() => {
        if (open) {
            setFormData({ ...defaultFormData, ...data });
            setSelectedTables(data?.userAccessibleTables?.map(x => ({ value: x, label: x })) || []);
        }
    }, [open, data])

    return (
        <Modal
            position="top-right"
            className="relative w-[40rem] px-8 py-10 bg-primary-3 text-white rounded-lg text-left"
            open={open}
        >
            <h1 className={`text-center text-2xl`}>Create Credentials</h1>

            {/* User Data */}
            {
                step === 0 && (
                    <div className="felx flex-col">
                        {/* User First Name */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`First Name`}
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            error={errors?.["firstName"]}
                        />

                        {/* User Last Name */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Last Name`}
                            name={`lastName`}
                            value={formData.lastName}
                            onChange={handleChange}
                            error={errors?.["lastName"]}
                        />

                        {/* User Email */}
                        <TextInput
                            readOnly={isEdit}
                            disabled={isEdit}
                            isRequired={!isEdit}
                            onBlur={handleBlur}
                            label={`Email`}
                            type={`email`}
                            name={`email`}
                            value={formData.email}
                            onChange={handleChange}
                            error={errors?.["email"]}
                        />

                        {/* Username */}
                        <TextInput
                            readOnly={isEdit}
                            disabled={isEdit}
                            isRequired={!isEdit}
                            onBlur={handleBlur}
                            label={`Username`}
                            name={`username`}
                            value={formData.username}
                            onChange={handleChange}
                            error={errors?.["username"]}
                        />

                        {/* User Role */}
                        <div className="flex flex-col gap-1">
                            <label className="block text-sm text-left">
                                <span>User Role</span>
                                <span className={`text-red-500 ml-[1px] text-base`}> *</span>
                            </label>
                            <UserRoleSelect
                                disabled={isEdit && user?.email === formData?.email}
                                selectedValue={formData?.userRole || null}
                                onChange={(newRole = null) => {
                                    setFormData({
                                        ...formData,
                                        userRole: newRole,
                                    });
                                }}
                            />
                            {
                                errors?.userRole &&
                                <span className="text-red-500 text-left text-sm">{errors.userRole}</span>
                            }
                        </div>

                        {/* User Password */}
                        {
                            !isEdit && (
                                <PasswordInput
                                    isRequired
                                    onBlur={handleBlur}
                                    label={`Password`}
                                    name={`password`}
                                    value={formData.password}
                                    onChange={handleChange}
                                    error={errors?.["password"]}
                                />
                            )
                        }


                        {/* Country Code */}
                        <TextInput
                            isRequired
                            placeholder={`e.g.(+91, +1, etc.)`}
                            onBlur={handleBlur}
                            label={`Country Code`}
                            name={`countryCode`}
                            value={formData.countryCode}
                            onChange={handleChange}
                            error={errors?.["countryCode"]}
                        />

                        {/* Contact Number */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Contact Number`}
                            name={`contactNumber`}
                            value={formData.contactNumber}
                            onChange={handleChange}
                            error={errors?.["contactNumber"]}
                        />

                        {/* <PhoneInput label="Contact Number" /> */}
                    </div>
                )
            }

            {/* Allowed Data Sources */}
            {
                step === 1 && (
                    <AllowedDataSources
                        value={selectedTables}
                        onChange={setSelectedTables}
                    />
                )
            }

            {/* Action Buttons */}
            <div className="flex justify-end mt-6 gap-4">
                {
                    step === 1 && (
                        <div className={`flex-grow`}>
                            <button
                                disabled={loading}
                                className="py-2 text-gray-300 px-4 border border-gray-200 rounded text-black"
                                onClick={() => setStep(0)}
                            >
                                Back
                            </button>
                        </div>
                    )
                }


                <button
                    disabled={loading}
                    className="py-2 text-gray-300 px-4 border border-gray-200 rounded text-black"
                    onClick={resetModalStates}
                >
                    Cancel
                </button>

                <button
                    disabled={loading}
                    className="py-2 bg-primary-1 text-gray-400 px-4 rounded text-white flex gap-2 items-center disabled:bg-gray-600 disabled:text-gray-300"
                    onClick={(e) => {
                        e.preventDefault();
                        if (step === 0) {
                            return handleNextStep();
                        }

                        handleSubmitForm();
                    }}
                >
                    {
                        loading && (
                            <img src={loadingGif} alt="loading" className={`h-4 w-4`} />
                        )
                    }
                    <span>
                        {step === 0 && "Next"}
                        {step === 1 && loading && "Saving"}
                        {step === 1 && !loading && "Save"}
                    </span>
                </button>
            </div>
        </Modal>
    );
}

export default ManageEmployeeDialog