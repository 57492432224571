import React, { useState, useEffect, useRef } from 'react';
import dialogueStyles from 'styles/dialogue.module.css';

import AppLogo from "images/dialogue/storymetrix chat bird.svg"
import { FileDrop } from 'react-file-drop';
import "styles/fileUpload.css"

import FileContainer from './FileContainer';
import { Link, useNavigate } from 'react-router-dom';
import DeleteFileModal from "components/DocAnalysis/DeleteFileModal"
import { useDocumentList } from '../../hooks/docAnalysisHooks';
import loadingGif from 'images/loader2.webp';
import { uploadDocFileToAnalyzeThunk } from '../../slices/chat/thunk';
import { useDispatch } from 'react-redux';

const FileUpload = () => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading: isLoadingDocuments, documents } = useDocumentList();

    const fileInputRef = useRef(null)

    // Handle file selection
    const getFileFromInput = (event) => {
        const input = event.target;
        if (!input.files?.length) {
            return null;
        }
        return input.files[0];
    };

    // Prepare form data
    const createFormData = (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return formData;
    };

    const handleFileUpload = async (file) => {
        try {
            setIsUploading(true);
            const formData = createFormData(file);
            const response = await dispatch(uploadDocFileToAnalyzeThunk(formData));
            setIsUploading(false);
            const docId = response?.payload?.id;
            if (docId) {
                navigate(`/analyze-doc/${docId}`);
            }
        } catch (error) {
            console.error('Upload error:', error);
            setIsUploading(false);
        }
    };

    const onTargetClick = () => {
        if (isUploading) {
            return false;
        }

        fileInputRef.current.click()
    }

    const onFileChange = async (event) => {
        try {
            const file = getFileFromInput(event);
            if (!file) {
                throw new Error('No file selected');
            }

            await handleFileUpload(file);
        } catch (error) {
            console.log("File upload Error: ", error);
        }
    }

    const onFileDrop = async (FileList) => {
        try {
            const file = FileList[0]
            await handleFileUpload(file);
        } catch (error) {
            console.log("File Drop Error: ", error);
        }
    }

    if (isLoadingDocuments) {
        return (
            <div className={`flex flex-col items-center justify-center mt-10`}>
                <img src={loadingGif} alt="loading" className={`h-6 w-6`} />
                <span>Loading your Documents</span>
            </div>
        )
    }

    return (
        <div className={`${dialogueStyles.chatContainerWrapper}`}>
            <div className={`p-5 gap-10 flex justify-center ${dialogueStyles.chatWrapper}`}>

                {/* File Upload window */}
                <div className={`flex-grow flex items-center justify-center`}>
                    <FileDrop

                        onDrop={onFileDrop}
                        onTargetClick={onTargetClick}
                    >

                        <div className={`flex flex-col items-center justify-center gap-4 h-full`}>
                            <img
                                alt={`Upload document`}
                                src={isUploading ? loadingGif : AppLogo}
                                className={`h-32 w-32`}
                            />
                            {
                                isUploading ? (
                                    <span className={`text-lg font-medium`}>Uploading your document...</span>
                                ) : (
                                    <span className={`text-lg font-medium`}>Drag or Upload your file here</span>
                                )
                            }


                        </div>

                        <input onChange={onFileChange} ref={fileInputRef} type={"file"} className={"hidden"} hidden />
                    </FileDrop>
                </div>

                {/* List the existing documents */}
                {
                    documents?.length > 0 && (
                        <div className={`flex flex-col items-center justify-start w-96 gap-2`}>

                            <h1 className={`text-lg font-medium`}>Chat with previous files</h1>

                            {
                                documents.map(doc => {
                                    return (
                                        <Link
                                            key={`document-${doc.id}`}
                                            to={`/analyze-doc/${doc.id}`}
                                            className={`w-full`}
                                        >
                                            <FileContainer document={doc} />
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    )
                }

                <DeleteFileModal />
            </div>
        </div>
    );
};

export default FileUpload;