import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setAddDashboardVisibility, setSelectedDashboard } from "slices/dashboard/reducer";
import Select, { components } from 'react-select';

// Images
import ArrowDownIcon from "images/arrow-down.svg";
import AddSquareIcon from "images/add-square-green-icon.svg";
import moment from "moment-timezone";

const selectStyles = {
    control: (provided) => ({
        ...provided,
        cursor: "pointer",
        backgroundColor: "#0B1723",
        color: "white",
        border: "1px solid #382F5C",
        padding: "6px 12px 6px 2px",
        outline: "none",
        textAlign: "left",

        ":hover": {
            border: "1px solid #666699",
            outline: "none",
        },

        ":focus": {
            border: "1px solid #666699",
            outline: "none",
        },

        ":active": {
            border: "1px solid #666699",
            outline: "none",
        },

    }),
    menu: (provided) => ({
        ...provided,
        color: "white",
        borderRadius: 10,
        overflow: "hidden",
        boxShadow: "0px 0px 4px 0px #7357EB40",
        backgroundColor: "#0B1723",
    }),
    option: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#0B1723",
        ":hover": {
            backgroundColor: "#132639",
        }
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: "white",
    }),
    multiValue: (baseStyles) => ({
        ...baseStyles,
        alignItems: "center",
        gap: "4px",
        backgroundColor: "#0D1B2A",
        color: "#ffffff",
        border: "1px solid #382F5C",
        borderRadius: "4px",
        padding: "6px",
    }),
    multiValueLabel: () => ({
        color: "#ffffff",
    }),
    multiValueRemove: () => ({}),
    input: (baseStyles) => ({
        ...baseStyles,
        color: "white",
    })
};


const options = moment.tz.names().map(x => ({ label: x, value: x }));

const TimezoneSelect = ({ onChange, selectedValue }) => {

    console.log("Selected Value", selectedValue)

    return (
        <Select
            placeholder={`Select Timezone...`}
            options={options}
            value={selectedValue}
            onChange={(newValue) => {
                onChange(newValue.value);
            }}
            backspaceRemovesValue={false}
            components={{
                IndicatorSeparator: null,
            }}
            isClearable={false}
            menuPosition={"absolute"}
            menuPlacement={"auto"}
            styles={selectStyles}
            tabSelectsValue={false}
        />
    )
}

export default TimezoneSelect;