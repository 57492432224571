import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFetchDocuments } from 'slices/chat/reducer';
import { getDocumentListThunk } from 'slices/chat/thunk';
export const useDocumentList = () => {
    // Local State
    const [loading, setLoading] = useState(true);

    // Redux State
    const dispatch = useDispatch();
    const { documents, fetchDocuments } = useSelector(state => state.ChatReducer);


    // Fetch Documents
    const fetchDocumentList = async () => {
        try {
            await dispatch(getDocumentListThunk());
        } catch (error) {
            console.error("Error fetching documents:", error);
        } finally {
            await dispatch(setFetchDocuments(false));
            setLoading(false);
        }
    }

    useEffect(() => {
        if (fetchDocuments) {
            setLoading(true);
            fetchDocumentList();
        } else {
            setLoading(false);
        }
    }, [fetchDocuments]);

    // Return data
    return {
        documents,
        isLoading: loading,
        documentCount: documents.length,
    }
}