import Layout from 'components/Layout';
import ManageCredentialsComponent from "components/admin/ManageCredentialsComponent";

const ManageCredentialsPage = () => {
    return (
        <Layout>
            <ManageCredentialsComponent />
        </Layout>
    );
};

export default ManageCredentialsPage;