import * as yup from "yup";
import { userDetailSchema } from 'validations/auth';


export const employeeSchema = userDetailSchema
    .concat(yup.object({
        userRole: yup.object({
            value: yup.string().required("User Role is required "),
            label: yup.string(),
        }).nonNullable("User Role is Required"),
        countryCode: yup
            .string()
            .required('Country code is required')  // Country code is required
            .matches(/^\+[1-9]{1}[0-9]{0,2}$/, 'Country code must be in international format e.g.(+91, +1, etc.)')  // Country code in international format (e.g., +91)
    }));