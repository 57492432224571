export const transformPlotData = (data) => {
	const newData = {};
	for (const [key, value] of Object.entries(data)) {
		if (key && value) {
			// lowercase the key and remove any special symbol
			const optimizedKey = key?.toLowerCase()?.replace(/[^a-z0-9]/g, '');
			if (optimizedKey) {
				newData[optimizedKey] = value;
			}
		}
	}
	return newData;
}

export const createBotMessage = (message) => {
	const botMessage = {
		query_id: message.query_id,
		sender: 'bot',
		content: message?.summary || message?.answer,
		copyContent: `summary - ${message?.summary_actions?.summary_text} actions - ${message?.summary_actions?.actions_text}`,
		actions: message?.actions,
		sqlQuery: message?.sql_query || '',
		firstMessage: 'no',

	};

	if (message?.df_data?.length >= 1 && message?.highchart_code?.length >= 1) {
		const chartObj = {
			graphTitle: message?.graph_head,
			graphType: message?.graph_type,
			graph: JSON.parse(message?.df_data),
			graphDataToPlot: JSON.parse(message?.highchart_code),
			viewType: "chart"
		};

		const requiredFields = ["title", "plottype", "xaxis", "yaxis"];
		const transformedPlotData = transformPlotData(chartObj?.graphDataToPlot || {});
		const hasRequiredFields = requiredFields.every(field => transformedPlotData.hasOwnProperty(field))

		if (hasRequiredFields) {
			Object.assign(botMessage, chartObj);
		} else {
			console.log("Not Enough data for chart", chartObj.graphDataToPlot)
		}
	}

	return botMessage;
};