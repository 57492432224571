import { useDispatch } from 'react-redux';
import { setDeleteDocumentVisibility, setSelectedDocument } from 'slices/chat/reducer';

// Images 
import FileIcon from "images/file-icon.svg";
import CrossIcon from "images/cross-icon.svg";

const FileContainer = ({ document }) => {

    const dispatch = useDispatch();

    const handleDeleteFile = async (event) => {
        try {
            event.preventDefault();
            event.stopPropagation();
            console.log("This is about to be deleted", document)
            if (!document) {
                return;
            }
            await dispatch(setSelectedDocument(document));
            await dispatch(setDeleteDocumentVisibility(true));
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    return (
        <div
            className="p-4 border border-primary-light w-full flex items-center rounded-lg gap-3"
        >
            <img src={FileIcon} alt="File" className="w-5 h-6" />

            {/* Middle Section */}
            <div className="flex flex-grow flex-col items-start overflow-hidden">
                <span title={`File: ${document.filename}`} className="text-sm truncate w-full">{document.filename}</span>
                <span title={`uploaded at: ${document.uploaded_at}`} className="text-xs text-gray-300 truncate w-full">{document.uploaded_at}</span>
            </div>

            {/* Button - Prevent Shrinking */}
            <button title="Remove document" onClick={handleDeleteFile} className="flex-shrink-0">
                <img src={CrossIcon} alt="Remove Document" className="w-5 h-6 opacity-70" />
            </button>
        </div>

    );
}

export default FileContainer;