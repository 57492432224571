import Modal from "components/Modal";
import { useState } from 'react';

const ConfirmDeleteModal = ({
    open = false,
    title = "Confirm Delete",
    content = "Do you want to delete this?",
    onConfirm,
    onCancel,
    confirmButtonText = "Confirm",
    cancelButtonText = "Cancel"
}) => {

    const [loading, setLoading] = useState(false);
    const handleDeleteAlert = async () => {
        setLoading(true);
        try {
            await onConfirm();
        } catch (err) {
            console.log("Delete Error: ", err)
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            // position="top-right"
            className="text-left mx-auto w-[40rem] px-8 py-10 bg-primary-3 text-white rounded-lg"
            open={open}
        >
            <h1>{title}</h1>
            <hr className="mt-2" />
            <p className="mt-6">{content}</p>

            <div className="flex justify-end mt-6 gap-4">
                <button disabled={loading} className="py-2 text-gray-300 px-4 border border-gray-200 rounded text-black" onClick={onCancel}>
                    {cancelButtonText}
                </button>
                <button disabled={loading} className="py-2 bg-primary-1 text-gray-400 px-4 rounded text-white" onClick={handleDeleteAlert}>
                    {confirmButtonText}
                </button>
            </div>
        </Modal>
    );
}

export default ConfirmDeleteModal;