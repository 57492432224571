import { useEffect, useState } from "react";
import { getAllRoles } from "api/admin/index";
import CreatableSelect from 'react-select/creatable';
import { createNewRole } from '../../api/admin/index';

const selectStyles = {
    control: (provided) => ({
        ...provided,
        cursor: "pointer",
        backgroundColor: "#0D1B2A",
        color: "white",
        border: "1px solid #382F5C",
        padding: "6px 12px 6px 2px",
        outline: "none",
        textAlign: "left",

        ":hover": {
            border: "1px solid #666699",
            outline: "none",
        },

        ":focus": {
            border: "1px solid #666699",
            outline: "none",
        },

        ":active": {
            border: "1px solid #666699",
            outline: "none",
        },

    }),
    menu: (provided) => ({
        ...provided,
        color: "white",
        borderRadius: 10,
        overflow: "hidden",
        boxShadow: "0px 0px 4px 0px #7357EB40",
        backgroundColor: "#0B1723",
    }),
    option: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#0B1723",
        ":hover": {
            backgroundColor: "#132639",
        }
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: "white",
    }),
    multiValue: (baseStyles) => ({
        ...baseStyles,
        alignItems: "center",
        gap: "4px",
        backgroundColor: "#0D1B2A",
        color: "#ffffff",
        border: "1px solid #382F5C",
        borderRadius: "4px",
        padding: "6px",
    }),
    multiValueLabel: () => ({
        color: "#ffffff",
    }),
    multiValueRemove: () => ({}),
    input: (baseStyles) => ({
        ...baseStyles,
        color: "white",
    })
};


const UserRoleSelect = ({ onChange, selectedValue, disabled }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [userRoles, setUserRoles] = useState([]);

    const fetchUserRoles = async () => {
        try {
            const response = await getAllRoles();
            const roles = response.data.map((x) => {
                return {
                    label: x.roleName,
                    value: x._id,
                }
            })
            setUserRoles(roles);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleOptionCreate = async (roleName) => {
        try {
            setIsLoading(true)
            const payload = {
                roleName,
            }
            const response = await createNewRole(payload);

            const newRole = {
                label: response.data.roleName,
                value: response.data._id
            }

            setUserRoles([...userRoles, newRole]);
            onChange(newRole);
            setIsLoading(false);
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (userRoles.length == 0) {
            fetchUserRoles();
        }
    }, []);

    return (
        <CreatableSelect
            isDisabled={isLoading || disabled}
            isLoading={isLoading}
            placeholder={`Select User Role...`}
            options={userRoles}
            value={selectedValue}
            onChange={(newValue) => {
                onChange(newValue);
            }}
            onCreateOption={handleOptionCreate}
            backspaceRemovesValue={false}
            components={{
                IndicatorSeparator: null,
            }}
            isClearable={true}
            menuPosition={"absolute"}
            menuPlacement={"auto"}
            styles={selectStyles}
            tabSelectsValue={false}
        />
    )
};

export default UserRoleSelect;
