import { getFirstQuestionListThunk, getChatHistoryListByDateThunk, getDocumentListThunk, deleteDocumentThunk, uploadDocFileToAnalyzeThunk } from './thunk';
import { createSlice } from "@reduxjs/toolkit";

const initialMessage = {
  sender: 'bot',
  content: 'Hello, how can I help you?',
  sqlQuery: '',
  firstMessage: 'yes'
}

const initialState = {
  fetchDocuments: true,
  documents: [],
  deleteDocumentVisibility: false,
  selectedDocument: null,
  chats: [initialMessage],
  history: [],
  errors: {}
}

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChats: (state, action) => {
      const payload = action.payload;
      state.chats = payload;
    },

    setHistoryChats: (state, action) => {
      const { date, data = [] } = action.payload;
      state[date] = data;
    },

    updateChats: (state, action) => {
      const { index, data } = action.payload;
      state.chats[index] = { ...state.chats[index], ...data };
    },

    updateHistoryChats: (state, action) => {
      const { index, date, data } = action.payload;
      state[date][index] = { ...state[date][index], ...data };
    },

    addNewChat: (state, action) => {
      const payload = action.payload;
      state.chats.push(payload);
    },

    addNewHistoryChat: (state, action) => {
      const { date, data } = action.payload;
      state[date].push(data);
    },

    resetChats: (state, action) => {
      state.chats = [initialMessage];
    },

    // Documents
    setFetchDocuments: (state, action) => {
      const payload = action.payload;
      state.fetchDocuments = payload
    },

    setSelectedDocument: (state, action) => {
      const payload = action.payload;
      state.selectedDocument = payload
    },

    setDeleteDocumentVisibility: (state, action) => {
      const payload = action.payload;
      state.deleteDocumentVisibility = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFirstQuestionListThunk.fulfilled, (state, action) => {
      state.history = action.payload;
    });
    builder.addCase(getFirstQuestionListThunk.rejected, (state, action) => {
      state.errors.history = action.payload || null;
    });

    // Document List 
    builder.addCase(getDocumentListThunk.fulfilled, (state, action) => {
      state.documents = action.payload.documents;
    });
    builder.addCase(getDocumentListThunk.rejected, (state, action) => {
      state.errors.documents = action.payload || null;
    });

    // Document Delete
    builder.addCase(deleteDocumentThunk.fulfilled, (state, action) => {
      const docId = action?.meta?.arg?.doc_id;
      if (docId) {
        state.documents = state.documents.filter(document => document.id !== docId);
      }
    });
    builder.addCase(deleteDocumentThunk.rejected, (state, action) => {
      state.errors.deleteDocument = action.payload || null;
    });

    // Upload Document `uploadDocFileToAnalyzeThunk`

    builder.addCase(uploadDocFileToAnalyzeThunk.fulfilled, (state, action) => {
      const newData = {
        id: action.payload.id,
        filename: action.payload.filename,
        uploaded_at: new Date().toISOString().replace("T", " ").slice(0, 19),
      }
      state.documents = [...state.documents, newData];
    });
    builder.addCase(uploadDocFileToAnalyzeThunk.rejected, (state, action) => {
      state.errors.uploadDocument = action.payload || null;
    });


    // builder.addCase(getChatHistoryListByDateThunk.fulfilled, (state, action) => {
    //   state[action.payload.date] = action.payload?.result?.reverse() || [];
    // });
    // builder.addCase(getChatHistoryListByDateThunk.rejected, (state, action) => {
    //   state.errors[action.date] = action.payload || null;
    // });
  },
});

export const {
  setChats,
  setHistoryChats,
  updateChats,
  updateHistoryChats,
  addNewChat,
  addNewHistoryChat,
  resetChats,
  setFetchDocuments,
  setSelectedDocument,
  setDeleteDocumentVisibility
} = chatSlice.actions;

export default chatSlice.reducer;