// Log events
export const logEvent = (action, category, label, value) => {
    if (typeof window.gtag === "function") {
        window.gtag("event", action, {
            event_category: category,
            event_label: label,
            value,
        });
    } else {
        console.warn("Google Analytics not initialized.");
    }
};

// Log page views
export const logPageView = (path) => {
    if (typeof window.gtag === "function") {
        window.gtag("config", "G-B9EKWCXKXN", {
            page_path: path,
        });
    } else {
        console.warn("Google Analytics not initialized.");
    }
};

// Set user ID
export const setUserId = (userId) => {
    if (typeof window.gtag === "function") {
        window.gtag("set", { user_id: userId });
    } else {
        console.warn("Google Analytics not initialized.");
    }
};
