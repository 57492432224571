import React from 'react';

// Images
import { Menu, MenuItem } from '@szhsin/react-menu';
import AddSquareGreenIcon from 'images/add-square-green-icon.svg';

import { useDispatch, useSelector } from 'react-redux';
import { setAddDashboardVisibility, setSelectedGraph } from 'slices/dashboard/reducer';
import { addChartToDashboardThunk } from '../../slices/dashboard/thunk';
import { notifySuccess } from "utils/customToasts";
import { logEvent } from 'utils/analytics';

// Images
import dashboardIcon from 'images/dashboard.svg';


const AddToDashboardButton = ({ data }) => {

    const dispatch = useDispatch();

    const { dashboards } = useSelector(state => state.DashboardReducer);

    const handleAddGraph = async (dashboard) => {
        try {
            const payload = {
                user_id: 1, // This is temporary
                dashid: dashboard.id,
                history_id: data.query_id
            }
            dispatch(setSelectedGraph(data));
            dispatch(addChartToDashboardThunk(payload));
            notifySuccess(`Graph added in ${dashboard?.name || "Dashboard"}`);

            // Log the click event to analytics
            logEvent("click", "ChatAction", "dashboard", dashboard?.name || "Dashboard");
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    const createNewDashboard = () => {
        dispatch(setSelectedGraph(data));
        dispatch(setAddDashboardVisibility(true));
    }

    return (
        <Menu
            menuClassName={`z-10 min-w-[13rem] bg-primary py-6 px-4 rounded shadow-primary-1`}
            menuButton={
                <div className="flex items-center">
                    <img
                        src={dashboardIcon}
                        alt="Change Value"
                        className={`h-6 w-6`}
                    />
                    DASHBOARD
                </div>
            }
            overflow={"auto"}
            position={"anchor"}

        >
            <MenuItem onClick={createNewDashboard} className={`cursor-pointer`}>
                <div
                    className="flex items-center gap-2"
                >
                    <span className="text-secondary-1 text-nowrap">Create new dashboard</span>
                    <img src={AddSquareGreenIcon} alt="Add Dashboard" />
                </div>
            </MenuItem>
            {
                dashboards.map((dashboard) => (
                    <MenuItem onClick={() => handleAddGraph(dashboard)} key={dashboard?.id} className={`cursor-pointer`}>
                        {dashboard?.name || "---"}
                    </MenuItem>
                ))
            }
        </Menu>
    );
};

export default AddToDashboardButton;
