import storyIcon from 'images/dialogue/storymetrix chat bird.svg';
import userIcon from 'images/dialogue/user profile.svg';

// Images
import copyIcon from 'images/dialogue/copy.svg';
import refreshIcon from 'images/refresh-2.svg';
import downvoteIcon from 'images/dialogue/thumbs.svg';
import { useState } from 'react';
import tickIcon from 'images/dialogue/tick.svg';
import UserAvatar from 'components/user/Avatar';

const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
        console.log('Text copied to clipboard');
        console.log(text);
    }).catch(err => {
        console.error('Could not copy text: ', err);
    });
};

const ChatHeader = ({ message, index, regenerate, downvote, handleStoryIconClick }) => {

    const [activeButtons, setActiveButtons] = useState({});

    const handleButtonClick = (buttonType) => {
        setActiveButtons(prevActiveButtons => ({
            ...prevActiveButtons,
            [buttonType]: true,
        }));

        if (buttonType === 'copy') {
            copyToClipboard(message.copyContent);
        } else if (buttonType === 'refresh' && typeof regenerate === "function") {
            regenerate();
        } else if (buttonType === 'downvote' && typeof downvote === "function") {
            downvote();
        }
        setTimeout(() => {
            setActiveButtons(prevActiveButtons => ({
                ...prevActiveButtons,
                [buttonType]: false,
            }));
        }, 2000);
    };

    return (
        <div className={`flex items-center gap-3 ${message.sender === 'bot' ? "justify-start" : "justify-end"}`}>
            <UserAvatar
                title={message.sender === 'bot' ? "Storymetrics Bot" : "Profile"}
                profileImage={message.sender === 'bot' ? storyIcon : null}
                username={message.sender === 'bot' ? "bot" : null}
                bgClasses={`h-6 w-6 ${message.sender === "bot" ? "bg-transparent" : ""}`}
                textClasses={"text-xs"}
                handleStoryIconClick={handleStoryIconClick}
            />

            {
                message?.graph && (
                    <>
                        {/* Refresh Button */}
                        <img
                            onClick={() => handleButtonClick("refresh")}
                            className={`h-5 w-5 cursor-pointer`}
                            src={refreshIcon}
                            alt="Regenerate"
                        />

                        {/* Copy Button */}
                        <img
                            onClick={() => handleButtonClick("copy")}
                            className={`h-5 w-5 cursor-pointer`}
                            src={activeButtons.copy ? tickIcon : copyIcon}
                            alt="Copy"
                        />

                        {/* Downvote Button */}
                        <img
                            onClick={() => handleButtonClick("downvote")}
                            className={`h-5 w-5 cursor-pointer`}
                            src={activeButtons.downvote ? tickIcon : downvoteIcon}
                            alt="Downvote"
                        />
                    </>
                )
            }
        </div>
    )
}

export default ChatHeader;