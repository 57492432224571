import DataTable from "react-data-table-component";
// Images
import TrashBinIcon from "images/trash-bin.svg";
import PenIcon from "images/pen-icon.svg";
import { useMemo } from "react";

const BG_COLOR = "#0D1B2A";
const TEXT_COLOR = "#FFFFFF";
const BORDER_COLOR = "#2c4662";
const BTN_COLOR = "#FFFFFF";
const BTN_COLOR_DISABLED = "#2c4662";

const TableView = ({ data = [] }) => {
    const customTheme = {
        table: {
            style: {
                border: `1px solid ${BORDER_COLOR}`,
                borderRadius: "8px 8px 0 0",
                overflow: "hidden",
                backgroundColor: BORDER_COLOR,
            }
        },

        tableWrapper: {
            style: {
                display: 'table',
            },
        },

        headRow: {
            style: {
                color: TEXT_COLOR,
                backgroundColor: BG_COLOR,
                borderBottom: "none",
                borderRadius: "8px 8px 0 0 ",
            },
        },

        rows: {
            style: {
                color: TEXT_COLOR,
                backgroundColor: BG_COLOR,
                borderBottom: "none !important",
            },
        },

        headCells: {
            style: {
                border: `1px solid ${BORDER_COLOR}`,
            }
        },

        cells: {
            style: {
                border: `1px solid ${BORDER_COLOR}`,
            }
        },
        pagination: {
            style: {
                color: TEXT_COLOR,
                backgroundColor: BG_COLOR,
                borderTopColor: BORDER_COLOR,
                border: `1px solid ${BORDER_COLOR}`,
                borderRadius: "0 0 8px 8px",
            },

            pageButtonsStyle: {
                borderRadius: '50%',
                height: '40px',
                width: '40px',
                padding: '8px',
                margin: 'px',
                cursor: 'pointer',
                transition: '0.4s',
                color: BTN_COLOR,
                fill: BTN_COLOR,
                backgroundColor: 'transparent',
                '&:disabled': {
                    cursor: 'unset',
                    color: BTN_COLOR_DISABLED,
                    fill: BTN_COLOR_DISABLED,
                },
                '&:hover:not(:disabled)': {
                    backgroundColor: BORDER_COLOR,
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: BORDER_COLOR,
                },
            },
        },
    }

    const dynamicColumns = () => {
        const columns = [];
        Object.keys(data[0]).forEach((col) => {
            const newCol = {
                name: col,
                selector: row => row?.[col] || "-NA-"
            }
            columns.push(newCol);
        })
        return columns;
    };

    const columns = useMemo(dynamicColumns, [data])

    return (
        <div className="w-full p-4 remove-select-svg">
            <DataTable
                data={data}
                columns={columns}
                customStyles={customTheme}
                pagination
            />
        </div>
    )
}

export default TableView;