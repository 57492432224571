import Axios from "api/Axios";


export const uploadDocFileToAnalyze = async (payload) => {
    try {
        const response = await Axios.post(
            "/upload/",
            payload,
            {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        return response.data;
    } catch (error) {
        if (Axios.isAxiosError(error)) {
            throw new Error(`Upload failed: ${error.message}`);
        }
        throw error;
    }
};

export const chatWithDoc = async (payload) => {
    try {
        const { doc_id, query } = payload;

        const response = await Axios.post("/chat/",
            {
                doc_id,
                query
            },
        );

        return response.data;
    } catch (error) {
        if (Axios.isAxiosError(error)) {
            throw new Error(`Upload failed: ${error.message}`);
        }
        throw error;
    }
}

export const getDocumentList = async () => {
    try {
        const response = await Axios.get("/list_documents/");
        return response.data;
    } catch (error) {
        if (Axios.isAxiosError(error)) {
            throw new Error(`Upload failed: ${error.message}`);
        }
        throw error;
    }
}

export const deleteDocument = async (payload) => {
    const { doc_id } = payload;
    try {
        const response = await Axios.delete(`/delete/${doc_id}`);
        return response.data;
    } catch (error) {
        if (Axios.isAxiosError(error)) {
            throw new Error(`Upload failed: ${error.message}`);
        }
        throw error;
    }
}