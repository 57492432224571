import AuthAxios from "api/Axios/authAxios";
import Axios from "api/Axios/index";

// Onboard Employee registration
export const onboardEmployee = async (payload) => {
    const response = await AuthAxios.post(`/employee`, payload);
    return response.data;
}

export const updateEmployee = async (payload) => {
    const { employeeId, ...data } = payload;
    const response = await AuthAxios.put(`/employee`, data, {
        params: {
            employeeId
        }
    });
    return response.data;
}

export const getAllCredentials = async (payload) => {
    const response = await AuthAxios.get(`/employee/all`, { params: payload });
    return response.data;
}

export const getAllRoles = async () => {
    const response = await AuthAxios.get(`/employee/role`);
    return response.data;
}

export const createNewRole = async (payload) => {
    const response = await AuthAxios.post(`/employee/role`, payload);
    return response.data;
}

export const getDataSourceList = async () => {
    const response = await Axios.get(`/tables/`);
    return response.data;
}