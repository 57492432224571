import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from 'images/main-logo.svg';
import { notifyError } from "utils/customToasts";
import { extractTenantName, getOrigin, generateTenantName } from 'utils/helper';
import { forgotPassword } from 'api/auth/index';

const ForgetPasswordComponent = () => {
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(true)

  const isFormValid = () => {
    return email;
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    try {
      if (!isFormValid()) {
        return notifyError("Please enter valid email address");
      }

      const origin = getOrigin();
      const tenantName = extractTenantName(origin, email);
      // const tenantName = generateTenantName(companyName);

      const payload = {
        email,
        tenantName
      }

      const response = await forgotPassword(payload);

      if (response?.statusCode !== "10000") {
        return notifyError(response?.message || "Failed to send email");
      }

      setIsEmailSent(true);
    } catch (err) {
      console.error(err);
      notifyError("An error occurred while sending email. Please try again later.");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#0D1B2A]">
      <div className={`${isEmailSent ? "" : "bg-[#0B1723]"} text-white p-8 rounded-lg w-full max-w-md`}>
        <img src={logo} alt="Storymetrix Logo" className="h-16 mx-auto mb-8" />

        {
          isEmailSent ? (
            <>
              <h2 className={`text-2xl mt-52 font-bold text-center mb-4 transition-opacity duration-300 ease-out`}>
                A Email has been sent!
              </h2>

              <p className={`text-center mb-6 transition-opacity duration-300 ease-out`}>
                We have sent temporary password on your registered email account. Please login with temporary password and change password from profile section.
              </p>

              <Link
                to={"/"}
                className={`block text-center w-full p-3 mt-4 rounded transform transition-transform duration-300 ease-out bg-purple-700 hover:bg-purple-800`}
              >
                Sign in
              </Link>
            </>
          ) : (
            <>
              <h2 className={`text-2xl font-bold text-center mb-4 transition-opacity duration-300 ease-out`}>
                Forgot password
              </h2>

              <p className={`text-center mb-6 transition-opacity duration-300 ease-out`}>
                Enter your registered email address. You will receive temporary password for login.
              </p>

              <div className={`transition-opacity duration-300 ease-out`}>

                {/* <div className="mb-4">
                  <label htmlFor="email" className="block mb-2 text-sm">Company Name</label>
                  <input
                    type="text"
                    id="companyName"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="w-full p-3 rounded bg-[#0D1B2A] border border-[#382F5C]"
                    placeholder="Enter your company name"
                  />
                </div> */}

                <div className="mb-4">
                  <label htmlFor="email" className="block mb-2 text-sm">Email address</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-3 rounded bg-[#0D1B2A] border border-[#382F5C]"
                    placeholder="Enter your Email Id"
                  />
                </div>

                <button
                  className={`w-full p-3 mt-4 rounded transform transition-transform duration-300 ease-out ${isFormValid() ? 'bg-purple-700 hover:bg-purple-800' : 'bg-gray-600 cursor-not-allowed'}`}
                  disabled={!isFormValid()}
                  onClick={handleButtonClick}
                >
                  Reset password
                </button>
              </div>
              <div className={`text-center mt-4 transition-opacity duration-300 ease-out`}>
                Already have an account?{' '}
                <Link to="/" className="text-purple-500 cursor-pointer">Sign In</Link>
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

export default ForgetPasswordComponent;