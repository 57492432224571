import { useMemo } from 'react';
import clsx from 'clsx';
const TextInput = ({
    label = "Input",
    error = "",
    className,
    isRequired = false,
    ...props
}) => {

    const inputClass = clsx('w-full p-3 rounded bg-[#0D1B2A] border border-[#382F5C] disabled:text-gray-500 disabled:cursor-not-allowed', className)

    const inputId = useMemo(() => {
        if (props.id) {
            return props.id;
        }
        return `text-input-${Math.random().toString(36).substring(2, 9)}`;
    }, [props?.id])

    return (
        <div className="flex flex-col gap-1">
            {
                label && (
                    <label htmlFor={inputId} className="block text-sm text-left">
                        <span>
                            {label}
                        </span>
                        {
                            isRequired && <span className={`text-red-500 ml-[1px] text-base`}> *</span>
                        }

                        {
                            props?.readOnly && (
                                <span className={`text-gray-400 font-medium text-xs`}> (Read Only)</span>
                            )
                        }
                    </label>
                )
            }

            <input
                autoComplete={"off"}
                id={inputId}
                className={inputClass}
                {...props}
            />
            <span className="text-red-500 text-left text-sm">{error}</span>
        </div>
    )
}

export default TextInput;