import * as yup from 'yup';
import { emailSchema } from 'validations/common';

const passwordSchema = yup
	.string()
	.required("Password is required")
// .min(8, "Password must be at least 8 characters long")
// .max(20, "Password must not exceed 20 characters")
// .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
// .matches(/[a-z]/, "Password must contain at least one lowercase letter")
// .matches(/[0-9]/, "Password must contain at least one number")
// .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character")
// .matches(/^\S*$/, "Password must not contain spaces");

// Login schema using the shared validation rules
export const LoginUserSchema = yup.object().shape({
	email: emailSchema,
	password: passwordSchema,
	tenantName: yup.string().nullable(),
});

// ! Deprecated Registration()
// Register schema using the shared validation rules and additional fields
// export const RegisterUserSchema = yup.object().shape({
//     email: emailSchema,
//     password: passwordSchema,
//     full_name: yup.string().trim().required("Please enter a full name"),
//     company_name: yup.string().trim().required("Please enter a company name"),
// });


/**
 * ----------------------------------------------------------------
 *                          Onboarding Schema
 * ----------------------------------------------------------------
 */

// Validation for addressInfo object
const addressInfoSchema = yup.object().shape({
	address1: yup.string().required('Address line 1 is required'),
	address2: yup.string().required('Address line 2 is required'),
	country: yup.string().required('Country is required'),
	city: yup.string().required('City is required'),
	state: yup.string().required('State is required'),
	zipcode: yup.string().matches(/^\d{6}$/, 'Zipcode must be a 6-digit number').required('Zipcode is required')
});

// Validation for userDetail object
export const userDetailSchema = yup.object().shape({
	isEdit: yup.boolean(),
	firstName: yup.string().required('First name is required'),
	lastName: yup.string().required('Last name is required'),
	email: yup.string().when("isEdit", {
		is: (value) => value === false,
		then: () => emailSchema,
		otherwise: (schema) => schema.optional().nullable()
	}),
	username: yup.string().required('Username is required'),
	password: yup.string().when("isEdit", {
		is: (value) => value === false,
		then: (schema) => schema.min(6, 'Password must be at least 6 characters').required('Password is required'),
		otherwise: (schema) => schema.optional().nullable()
	}),
	contactNumber: yup.string().matches(/^\d{10}$/, 'Contact number must be a 10-digit number').required('Contact number is required')
});

// Validation for employeeConfigs object
const employeeConfigsSchema = yup.object().shape({
	employeeIdPrefix: yup.string().max(3, "Employee ID prefix can't be more than 3 characters").required('Employee ID prefix is required')
});

// Main validation schema
const onboardValidationSchema = yup.object().shape({
	name: yup.string().required('Organization name is required'),
	websiteLink: yup.string()
		.trim()
		.test('is-empty-or-valid-url', 'Enter correct URL!', (value) => {
			if (value === "") return true; // Allow empty string
			return /((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(value);
		}),
	workingDays: yup.array()
		.of(yup.string().oneOf([
			'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
		]))
		.min(1, "Please select at least one day")
		.required('Working days are required'),
	branchId: yup.string().required('Branch ID is required'),
	branchName: yup.string().required('Branch name is required'),
	branchTimezoneName: yup.string().required('Branch timezone is required'),
	addressInfo: addressInfoSchema, // Referencing the extracted address validation schema
	userDetail: userDetailSchema, // Referencing the extracted userDetail validation schema
	employeeConfigs: employeeConfigsSchema, // Referencing the extracted employeeConfigs validation schema
	industry: yup.string().required('Industry is required'),
	termsAndCondition: yup.boolean().oneOf([true], 'Terms and conditions must be accepted').required('Terms and conditions are required'),
	numberOfEmployees: yup
		.string()
		.matches(/^\d{1,3}(-\d{1,3})?$/, 'Number of employees should be either a single value or a range (e.g., 100 or 100-150)')
		.required('Number of employees is required'),
});

export default onboardValidationSchema;
