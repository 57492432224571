import Layout from 'components/Layout';
import ChangePasswordComponent from "components/auth/ChangePassword";

const ChangePasswordPage = () => {
    return (
        <Layout>
            <ChangePasswordComponent />
        </Layout>
    );
};

export default ChangePasswordPage;