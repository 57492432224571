import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { notifySuccess, notifyError } from "utils/customToasts";
import { deleteDocumentThunk } from 'slices/chat/thunk';
import { setDeleteDocumentVisibility, setSelectedDocument } from 'slices/chat/reducer';

const DeleteFileModal = () => {

    const { deleteDocumentVisibility, selectedDocument } = useSelector(state => state.ChatReducer);
    const dispatch = useDispatch();

    const handleOnClose = () => {
        dispatch(setSelectedDocument(null));
        dispatch(setDeleteDocumentVisibility(false));
    }

    const handleDelete = async () => {
        try {
            // Check for chart id
            if (!selectedDocument?.id) {
                console.error("No such document found to delete");
                return;
            }

            // create the delete payload
            const deletePayload = {
                doc_id: selectedDocument?.id
            }

            // delete the chart from the dashboard
            dispatch(deleteDocumentThunk(deletePayload));

            notifySuccess("Document deleted successfully");

            // close the delete model
            handleOnClose();
        } catch (error) {
            notifyError(error?.message || "Error while deleting document");
            handleOnClose();
            return;
        }
    }

    return (
        <ConfirmDeleteModal
            open={deleteDocumentVisibility}
            title="Delete Document"
            content={`Are you sure you want to delete ${selectedDocument?.filename ?? "this file"}?`}
            onConfirm={handleDelete}
            onCancel={handleOnClose}
        />
    )
}

export default DeleteFileModal;