import Modal from "components/Modal";
import { useState } from 'react';
import Dropdown from 'components/Dropdown';
import { generateDropdownOptions } from 'utils/helper';
import ForeCastChart from "components/ForecastChart";

// Images
import CrossIcon from "images/cross-icon.svg";
import InfoIcon from "images/info-icon.svg";
import loadingGif from 'images/loader2.webp';


const forecastInfo = {
    ARIMA: {
        what: "ARIMA (AutoRegressive Integrated Moving Average) is a popular statistical model used for time series forecasting.",
        how: "The forecasted values are plotted against the historical data, and confidence intervals are often shown to indicate the range in which future values are expected to fall."
    },
    "Monte Carlo": {
        what: "Monte Carlo simulation uses random sampling and statistical modeling to estimate mathematical functions and mimic the operations of complex systems. It’s useful for understanding the impact of risk and uncertainty in prediction and forecasting models.",
        how: "The simulation generates multiple possible outcomes based on random variations in the inputs, and the results are displayed as a range of possible future scenarios. Look for the spread of the results to understand the level of uncertainty."
    },
    MCMC: {
        what: "Markov Chain Monte Carlo (MCMC) is a method for sampling from a probability distribution and estimating the distribution of model parameters. It’s particularly useful when dealing with complex models where direct sampling is difficult.",
        how: "MCMC results are usually presented as a series of distributions for each parameter in the model. The shapes of these distributions can indicate how well certain parameter values fit the data."
    },
    DES: {
        what: "Discrete Event Simulation (DES) is a method of simulating the behavior and performance of a real-life process, system, or operation by modeling the system as a sequence of individual events in time. It’s useful for understanding and optimizing complex processes.",
        how: "The simulation output will often show the timing and sequence of events, along with metrics like resource utilization, wait times, and throughput. Analyzing these outputs can help identify bottlenecks and opportunities for improvement."
    },
    "Scenario Analysis": {
        what: "Scenario analysis is a process of analyzing possible future events by considering alternative possible outcomes (scenarios). This is particularly useful in forecasting, risk management, and decision-making under uncertainty.",
        how: "Scenarios are presented as different potential futures, each based on different sets of assumptions. Comparing these scenarios can help understand the range of possible outcomes and prepare for different eventualities."
    }
};

const ForecastModal = ({
    open = false,
    loading,
    messageData,
    onChange,
    onClose,
}) => {

    const forecastOptions = generateDropdownOptions(messageData);

    return (
        <Modal
            className="mx-auto max-w-[60rem] w-full px-8 py-10 bg-primary-3 text-white rounded-lg"
            open={open}
        >
            <div className="flex justify-between items-center ">
                <h1 className="text-3xl">Forecast</h1>
                <img
                    onClick={onClose}
                    className="cursor-pointer h-5 w-5"
                    alt={"Close Forecast"}
                    src={CrossIcon}
                />
            </div>

            <hr className="mt-2 mb-10 border border-gray-400" />

            {loading && (
                <div className="flex items-center justify-center my-2 gap-2" >
                    <div>
                        <img src={loadingGif} alt="loading" style={{ height: '24px' }} />
                    </div>
                    <span>Loading Forecast...</span>
                </div>
            )}

            {
                !loading && (
                    <>
                        <Dropdown
                            hasError={null}
                            selectedOption={messageData?.forecastOption}
                            onChange={(data) => onChange(data, messageData)}
                            options={forecastOptions}
                        />

                        {
                            messageData?.forecastData && messageData?.forecastOption && (
                                <>
                                    <div className="mt-8 flex items-center justify-between">
                                        <p className="text-lg">{messageData?.forecastOption?.label}</p>

                                        <div className="has-tooltip relative flex items-center">
                                            <img
                                                className="cursor-pointer h-5 w-5 brightness-200"
                                                alt={"Close Forecast"}
                                                src={InfoIcon}
                                            />

                                            <div className="tooltip w-screen max-w-96 rounded shadow-lg p-4 bg-primary-3 border border-[#32636a] top-8 -right-1">
                                                <h1 className="font-bold">What it is:</h1>
                                                <p className="text-gray-200">{forecastInfo?.[messageData?.forecastOption?.forecastType]?.what}</p>

                                                <h1 className="font-bold mt-4">How to read it:</h1>
                                                <p className="text-gray-200">{forecastInfo?.[messageData?.forecastOption?.forecastType]?.how}</p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={`mt-3 mb-[10px]`} style={{ border: '1px solid #BEEF9E', borderRadius: '15px' }}>
                                        <ForeCastChart
                                            data={messageData?.forecastData?.forecast_result?.plot_data?.[messageData?.forecastOption?.column][messageData?.forecastOption?.forecastType] || null}
                                            selectedOption={messageData.forecastOption}
                                        />
                                    </div>
                                </>
                            )
                        }
                    </>
                )
            }

        </Modal>
    );
}

export default ForecastModal;