import styles from 'styles/home.module.css';
import ForecastButton from 'components/Dropdown/forecast';
import { generateDropdownOptions } from 'utils/helper';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateChats } from 'slices/chat/reducer';

// images
import chartIcon from "images/chart-icon.svg"
import tableIcon from "images/presention-chart.svg"
import exportIcon from "images/download-icon.svg"
import chatIcon from 'images/chat-alt.svg';
import AddToDashboardButton from 'components/ChatHistory/AddToDashboardButton';
import ChatActionButton from 'components/Chat/ChatActionButton';
import { setSelectedGraph, setShareCollabrationVisibility } from 'slices/collabration/reducer';
import { notifyError } from 'utils/customToasts';
import ExportComponent from './ExportComponent';
import { transformPlotData } from 'utils/chat';
import { logEvent } from 'utils/analytics';


const ChatActions = ({ message, index, forecastClick }) => {

    const [dataView, setDataView] = useState("chart");

    const dispatch = useDispatch();

    const handleButtonClick = (type, i) => {
        if (type === "view") {
            const newView = dataView === "chart" ? "table" : "chart"
            setDataView(newView);
            const payload = { data: { ...message, viewType: newView }, index: index }
            dispatch(updateChats(payload));

            // Log the click event to analytics
            logEvent("click", "ChatAction", "viewType", newView);
        }
    }

    const openShareGraphModal = () => {
        dispatch(setSelectedGraph(message));
        dispatch(setShareCollabrationVisibility(true));
    }

    const dataToPlotOptimized = transformPlotData(message?.graphDataToPlot || {});
    const chartTitle = dataToPlotOptimized?.title || message?.graphTitle || "Report"

    const handleForeCastClick = (data) => {
        forecastClick(data, message, index);

        // Log the click event to analytics
        logEvent("click", "ChatAction", "forecast", data.forecastType);
    }

    return (
        <div className={styles.messageOptions}>
            <ChatActionButton
                onClick={() => handleButtonClick('view', index)}
                icon={dataView === "chart" ? tableIcon : chartIcon}
                label={dataView === "chart" ? "TABLE VIEW" : "CHART VIEW"}
            />

            {/* Export Data with multiple options */}
            <ExportComponent title={chartTitle} data={message?.graph || []} />

            <AddToDashboardButton data={message} />

            {/* Forecast Button */}
            <ForecastButton
                options={generateDropdownOptions(message, index)}
                onChange={(data) => handleForeCastClick(data)}
            />

            <ChatActionButton
                onClick={openShareGraphModal}
                icon={chatIcon}
                label={`THREAD`}
            />
        </div>
    )
}

export default ChatActions;