import React from 'react';
import UserOnboardForm from 'components/auth/UserOnboardForm';

const OnboardingForm = () => {
    return (
        <div>
            <UserOnboardForm />
        </div>
    );
};

export default OnboardingForm;