import React, { useState } from 'react';
import { notifySuccess, notifyError } from "utils/customToasts";
import onboardValidationSchema from 'validations/auth';
import { onboardTenant } from 'api/auth';
import WeekdaySelect from 'components/Dropdown/weekdaySelect';
import TimezoneSelect from 'components/Dropdown/timezoneSelect';
import { useNavigate } from 'react-router';
import TextInput from 'components/Base/TextInput';
import PasswordInput from 'components/Base/PasswordInput';

// Images
import logo from 'images/main-logo.svg';
import CheckIcon from 'images/check-square-icon.svg';
import UncheckIcon from 'images/uncheck-square-icon.svg';
import loadingGif from 'images/loader2.webp';
import { getDataSourceList } from 'api/admin/index';


const defaultFormData = {
    name: "",
    imageUrl: "",
    websiteLink: "",
    workingDays: [],
    branchId: "",
    branchName: "",
    branchTimezoneName: "",
    addressInfo: {
        address1: "",
        address2: "",
        country: "",
        city: "",
        state: "",
        zipcode: ""
    },
    userDetail: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
        contactNumber: ""
    },
    employeeConfigs: {
        employeeIdPrefix: ""
    },
    industry: "",
    termsAndCondition: false,
    numberOfEmployees: ""
}

const UserOnboardForm = () => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(defaultFormData);
    const [workingDays, setWorkingDays] = useState([]);
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    // Handle field change
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked
            });
        } else if (name.includes("addressInfo") || name.includes("userDetail") || name.includes("employeeConfigs")) {

            if (name === "employeeConfigs.employeeIdPrefix" && value?.length > 3) {
                return;
            }

            const [parentKey, childKey] = name.split(".");
            setFormData({
                ...formData,
                [parentKey]: {
                    ...formData[parentKey],
                    [childKey]: value
                }
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    // Handle form validation
    const validateForm = async () => {
        try {
            const payload = {
                ...formData,
                workingDays: workingDays.map(x => x.value),
                userDetail: { ...formData.userDetail, isEdit: false }
            }
            await onboardValidationSchema.validate(payload, { abortEarly: false });
            return true;
        } catch (err) {
            const newErrors = err.inner.reduce((acc, error) => {
                acc[error.path] = error.message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    // Validation for specific field
    const validateField = async (name, value) => {
        try {
            const payload = {
                ...formData,
                userDetail: { ...formData.userDetail, isEdit: false }
            }
            await onboardValidationSchema.validateAt(name, payload);
            setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
        } catch (err) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: err.message }));
        }
    };

    // Handle blur event for input fields
    const handleBlur = (e) => {
        const { name, value } = e.target;
        validateField(name, value);  // Validate the field on blur
    };

    const userTables = async () => {
        try {
            const response = await getDataSourceList();
            return response.tables;
        } catch (error) {
            return [];
        }
    }

    // Submit onboard form
    const handleSubmitForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        const isValid = await validateForm();
        if (isValid) {
            try {
                // TODO: Image Issue with register.
                const userAccessibleTables = await userTables();

                const newPayload = { 
                    ...formData,
                    imageUrl: "noImage",
                    userAccessibleTables: userAccessibleTables || []
                }

                const response = await onboardTenant(newPayload);
                
                if (response?.statusCode !== "10000") {
                    setLoading(false);
                    notifyError(response?.message || "onboarding failed");
                    return;
                }

                setLoading(false);
                notifySuccess(response?.message || 'Onboarding complete! Please Login');
                navigate(response?.data?.workspaceLink || "/");
            } catch (error) {
                setLoading(false);
                notifyError(error?.response?.data?.message || error?.message || 'Something went wrong during submission');
            }
        } else {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen p-6 flex items-center justify-center bg-[#0D1B2A]">
            <div className="bg-[#0B1723] text-white p-8 rounded-lg w-full max-w-5xl">
                <img src={logo} alt="Storymetrics Logo" className="h-16 mx-auto mb-8" />

                <h1 className="text-xl text-center mb-8">User onboarding</h1>

                <form onSubmit={handleSubmitForm} className={`flex flex-col gap-4`}>

                    {/* Organization Details */}
                    <fieldset className="grid grid-cols-1 md:grid-cols-2 gap-6 rounded border border-gray-500 p-4">
                        <legend className={`px-2 font-semibold text-sm text-gray-500`}>Organization Details</legend>
                        {/* Organization Name */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Organization Name`}
                            name={`name`}
                            value={formData.name}
                            onChange={handleChange}
                            error={errors?.name}
                        />

                        {/* Organization Website Link */}
                        <TextInput
                            required={false}
                            onBlur={handleBlur}
                            label={`Website Link`}
                            type={`string`}
                            name={`websiteLink`}
                            value={formData.websiteLink}
                            onChange={handleChange}
                            error={errors?.websiteLink}
                        />

                        {/* Branch Name */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Branch Name`}
                            name={`branchName`}
                            value={formData.branchName}
                            onChange={handleChange}
                            error={errors?.branchName}
                        />

                        {/* Branch ID */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Branch ID`}
                            name={`branchId`}
                            value={formData.branchId}
                            onChange={handleChange}
                            error={errors?.branchId}
                        />

                        {/* Working Days */}
                        <div className="flex flex-col gap-1">
                            <label className="block text-sm text-left">
                                <span>Working Days</span>
                                {/* <span className={`text-red-500 ml-[1px] text-base`}> *</span> */}
                            </label>
                            <WeekdaySelect
                                selectedOption={workingDays}
                                onChange={setWorkingDays}
                            />
                            {
                                errors?.workingDays &&
                                <span className="text-red-500 text-left text-sm">{errors.workingDays}</span>
                            }
                        </div>

                        {/* Branch Timezone */}
                        <div className="flex flex-col gap-1">
                            <label className="block text-sm text-left">
                                <span>Branch Timezone</span>
                                <span className={`text-red-500 ml-[1px] text-base`}> *</span>
                            </label>
                            <TimezoneSelect
                                selectedValue={formData?.branchTimezoneName ? { label: formData.branchTimezoneName, value: formData.branchTimezoneName } : null}
                                onChange={(newTz) => {
                                    setFormData({
                                        ...formData,
                                        branchTimezoneName: newTz || "",
                                    });
                                }}
                            />
                            {
                                errors?.branchTimezoneName &&
                                <span className="text-red-500 text-left text-sm">{errors.branchTimezoneName}</span>
                            }

                        </div>

                        {/* Industry */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Industry`}
                            name={`industry`}
                            value={formData.industry}
                            onChange={handleChange}
                            error={errors?.industry}
                        />

                        {/* Number of Employees */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            placeholder={`e.g. 100-150`}
                            label={`Number of Employees`}
                            name={`numberOfEmployees`}
                            value={formData.numberOfEmployees}
                            onChange={handleChange}
                            error={errors?.numberOfEmployees}
                        />

                    </fieldset>

                    {/* Employee Details */}
                    <fieldset className="grid grid-cols-1 md:grid-cols-2 gap-6 rounded border border-gray-500 p-4">
                        <legend className={`px-2 font-semibold text-sm text-gray-500`}>Employee Configs</legend>
                        {/* Employee ID Prefix */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Employee Id Prefix`}
                            name={`employeeConfigs.employeeIdPrefix`}
                            value={formData.employeeConfigs.employeeIdPrefix}
                            onChange={handleChange}
                            error={errors?.["employeeConfigs.employeeIdPrefix"]}
                        />
                    </fieldset>

                    {/* User Details */}
                    <fieldset className="grid grid-cols-1 md:grid-cols-2 gap-6 rounded border border-gray-500 p-4">
                        <legend className={`px-2 font-semibold text-sm text-gray-500`}>User Details</legend>
                        {/* User First Name */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`First Name`}
                            name="userDetail.firstName"
                            value={formData.userDetail.firstName}
                            onChange={handleChange}
                            error={errors?.["userDetail.firstName"]}
                        />

                        {/* User Last Name */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Last Name`}
                            name={`userDetail.lastName`}
                            value={formData.userDetail.lastName}
                            onChange={handleChange}
                            error={errors?.["userDetail.lastName"]}
                        />

                        {/* User Email */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Email`}
                            type={`email`}
                            name={`userDetail.email`}
                            value={formData.userDetail.email}
                            onChange={handleChange}
                            error={errors?.["userDetail.email"]}
                        />

                        {/* Username */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Username`}
                            name={`userDetail.username`}
                            value={formData.userDetail.username}
                            onChange={handleChange}
                            error={errors?.["userDetail.username"]}
                        />


                        {/* User Password */}
                        <PasswordInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Password`}
                            name={`userDetail.password`}
                            value={formData.userDetail.password}
                            onChange={handleChange}
                            error={errors?.["userDetail.password"]}
                        />

                        {/* Contact Number */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Contact Number`}
                            name={`userDetail.contactNumber`}
                            value={formData.userDetail.contactNumber}
                            onChange={handleChange}
                            error={errors?.["userDetail.contactNumber"]}
                        />
                    </fieldset>

                    {/* Address Details */}
                    <fieldset className="grid grid-cols-1 md:grid-cols-2 gap-6 rounded border border-gray-500 p-4">
                        <legend className={`px-2 font-semibold text-sm text-gray-500`}>Address Details</legend>
                        {/* Address Line 1 */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Address Line 1`}
                            name="addressInfo.address1"
                            value={formData.addressInfo.address1}
                            onChange={handleChange}
                            error={errors?.["addressInfo.address1"]}
                        />

                        {/* Address Line 2 */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Address Line 2`}
                            name="addressInfo.address2"
                            value={formData.addressInfo.address2}
                            onChange={handleChange}
                            error={errors?.["addressInfo.address2"]}
                        />

                        {/* Country */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Country`}
                            name="addressInfo.country"
                            value={formData.addressInfo.country}
                            onChange={handleChange}
                            error={errors?.["addressInfo.country"]}
                        />

                        {/* City */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`City`}
                            name="addressInfo.city"
                            value={formData.addressInfo.city}
                            onChange={handleChange}
                            error={errors?.["addressInfo.city"]}
                        />

                        {/* State */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`State`}
                            name="addressInfo.state"
                            value={formData.addressInfo.state}
                            onChange={handleChange}
                            error={errors?.["addressInfo.state"]}
                        />

                        {/* Zipcode */}
                        <TextInput
                            isRequired
                            onBlur={handleBlur}
                            label={`Zipcode`}
                            name="addressInfo.zipcode"
                            value={formData.addressInfo.zipcode}
                            onChange={handleChange}
                            error={errors?.["addressInfo.zipcode"]}
                        />
                    </fieldset>

                    <div className="py-5 px-6 flex items-center gap-4 border rounded-lg border-primary-light">
                        <div className="relative">
                            <input
                                name="termsAndCondition"
                                type="checkbox"
                                onChange={handleChange}
                                className={`absolute cursor-pointer opacity-0 w-full h-full`}
                            />
                            <img
                                src={formData.termsAndCondition ? CheckIcon : UncheckIcon}
                                alt={formData.termsAndCondition ? "Deselect" : "Select"}
                                className="cursor-pointer"
                            />
                        </div>
                        <span>I am agree to the terms & conditions</span>
                    </div>

                    {/* Submit Button */}
                    <div className="col-span-full flex justify-end mt-6">
                        <button
                            type="submit"
                            className={`p-3 bg-primary-1 rounded text-white disabled:opacity-50`}
                            disabled={!formData?.termsAndCondition || loading}
                        >
                            {loading ? (
                                <img src={loadingGif} alt="loading" className="h-5 w-5" />
                            ) : (
                                "Complete Registration"
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UserOnboardForm;
