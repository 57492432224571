import styles from "styles/dialogue.module.css";
import Layout from "components/Layout";
import FileUpload from "components/DocAnalysis/FileUpload";

const ChatWindow = () => {

    return (
        <Layout>
            <div className={styles.newDialog}>
                <FileUpload />
            </div>
        </Layout>
    );
};

export default ChatWindow;
