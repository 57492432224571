import HighchartsComponentAlt from "components/HighchartsComponentAlt";
import MoreButton from "components/Dashboard/MoreButton";
import { useDispatch } from "react-redux";
import { setAddAlertVisibility, setDeleteGraphVisibility, setGraphModalVisibility, setSelectedGraph, setAddDashboardVisibility, setMoveChartData } from 'slices/dashboard/reducer';
import { moveChartToDashboardThunk } from "slices/dashboard/thunk";
import { notifyError, notifyInfo } from "utils/customToasts";
import { getJSONObject } from "utils/helper";

// Images
import FullScreenIcon from "images/full-screen-icon.svg";

const DashBoardGraph = ({ chartData, selectedDashboard }) => {

    const tableData = getJSONObject(chartData?.updated_df_data ? chartData?.updated_df_data : chartData.df_data);
    const dataToPlot = getJSONObject(chartData.highchart_code);

    const graphCreatedAt = new Date(chartData.created_at);

    const dashboardData = {
        ...chartData,
        table: tableData,
        highchart_code: dataToPlot,
        createdAtDate: graphCreatedAt.toLocaleDateString(),
        createdAtTime: `${graphCreatedAt.getHours()}:${graphCreatedAt.getMinutes()}`,
    }

    const dispatch = useDispatch();

    const handleCreateAlert = () => {
        dispatch(setSelectedGraph(dashboardData));
        dispatch(setAddAlertVisibility(true));
    }

    const handleDeleteGraph = () => {
        dispatch(setSelectedGraph(dashboardData));
        dispatch(setDeleteGraphVisibility(true));
    }

    const handleMoveGraph = async (data) => {
        console.log("Selected graph data => ", data);
        const payload = {
            chartid: dashboardData?.chartid || null,
            old_dashid: dashboardData?.dashboard_id || null,
            new_dashid: data?.id || null,
        }
        const dispatchResponse = await dispatch(moveChartToDashboardThunk(payload));
        console.log(dispatchResponse);
        if (dispatchResponse?.type?.endsWith("/fulfilled")) {
            notifyInfo(`Chart moved in ${data.name}`);
        }

        if (dispatchResponse?.type?.endsWith("/rejected")) {
            notifyError(`Cannot move chart`);
        }
    }

    const handleGraphMaximize = () => {
        dispatch(setSelectedGraph({
            ...dashboardData,
            dashboard_name: selectedDashboard,
        }));
        dispatch(setGraphModalVisibility(true));
    }

    const handleMoveWithCreate = () => {
        const payload = {
            chartid: dashboardData?.chartid || null,
            old_dashid: dashboardData?.dashboard_id || null,
        }
        dispatch(setMoveChartData(payload));
        dispatch(setAddDashboardVisibility(true));
    }

    return (
        <div className="flex flex-col gap-2 p-4 border border-[#BEEF9E66] rounded-lg">
            <HighchartsComponentAlt
                chartTypeOuter={dashboardData.graph_type}
                chartTitle={dashboardData.highchart_code.Title}
                data={dashboardData.table}
                dataToPlot={dashboardData.highchart_code}
            />

            <hr className="border-[#BEEF9E66]" />

            <div className={`flex justify-between items-center `}>
                <div>
                    <h2 className="font-medium text-sm">{selectedDashboard}</h2>
                    <div className="flex gap-2 items-center text-xs text-[#DADCDE]">
                        <span>
                            Created on {dashboardData?.createdAtDate} at {dashboardData?.createdAtTime}
                        </span>

                        <div className="h-5 max-h-full w-[1px] bg-[#00264E]"></div>

                        {
                            dashboardData?.alert_id && (
                                <span className="text-[#BEEF9E99]">
                                    Alert Created
                                </span>
                            )
                        }

                    </div>

                </div>

                <div className="flex gap-4 items-center">
                    <MoreButton
                        hasAlert={dashboardData?.alert_id}
                        onCreateAlert={handleCreateAlert}
                        onDeleteGraph={handleDeleteGraph}
                        onMoveGraph={handleMoveGraph}
                        onMoveWithCreate={handleMoveWithCreate}
                    />

                    <button onClick={handleGraphMaximize} className={`p-1 border border-primary-light rounded-lg`}>
                        <img className={`h-6 w-6`} src={FullScreenIcon} alt="Full-Screen" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DashBoardGraph;