import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteDocument, getDocumentList } from "api/docAnalysis";
import { getChatHistoryListByDate, getFirstQuestionList, getUniqueQuestionList, deleteHistoryByDate } from "api/history";
import { uploadDocFileToAnalyze } from '../../api/docAnalysis/index';

export const getFirstQuestionListThunk = createAsyncThunk(
	"/getFirstQuestionList",
	async (data) => {
		try {
			const response = await getFirstQuestionList(data);
			return response;
		} catch (error) {
			return error;
		}
	}
);

export const getChatHistoryListByDateThunk = createAsyncThunk(
	"/getChatHistoryListByDate",
	async (data) => {
		try {
			const response = await getChatHistoryListByDate(data);
			return {
				date: data.date,
				result: response
			};
		} catch (error) {
			return error;
		}
	}
);

export const getUniqueQuestionListThunk = createAsyncThunk(
	"getUniqueQuestionList",
	async (data) => {
		try {
			const response = await getUniqueQuestionList(data);
			return response;
		} catch (error) {
			return error;
		}
	}
)

export const deleteHistoryByDateThunk = createAsyncThunk(
	"deleteHistoryByDate",
	async (data) => {
		try {
			const response = await deleteHistoryByDate(data);
			return response;
		} catch (error) {
			return error;
		}
	}
)

export const getDocumentListThunk = createAsyncThunk(
	"/getDocumentList",
	async () => {
		try {
			const response = await getDocumentList();
			return response;
		} catch (error) {
			return error;
		}
	}
);

export const deleteDocumentThunk = createAsyncThunk(
	"deleteDocument",
	async (payload) => {
		try {
			const response = await deleteDocument(payload);
			return response;
		} catch (error) {
			return error;
		}
	}
)

export const uploadDocFileToAnalyzeThunk = createAsyncThunk(
	"uploadDocFileToAnalyze",
	async (payload) => {
		try {
			const response = await uploadDocFileToAnalyze(payload);
			return response;
		} catch (error) {
			return error;
		}
	}
)
