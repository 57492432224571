import React from 'react';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import exportIcon from 'images/download-icon.svg';
import { logEvent } from 'utils/analytics';
import "jspdf-autotable";

const ExportComponent = ({ data, title = "Report" }) => {

	// Function to export to PDF
	const handleDownloadPDF = () => {
		const doc = new jsPDF();

		// Dynamically get column headers from the data (keys of the first object)
		const headers = Object.keys(data[0]);

		// Set title and general font styles
		doc.setFont('Helvetica', 'normal');
		doc.setFontSize(18);
		doc.text(title, 14, 20);

		// Initialize page count
		let pageCount = 0;

		// Generate the table with varying column counts
		doc.autoTable({
			head: [headers],  // Dynamically generated headers based on the data
			body: data.map(row => headers.map(header => `${row[header]}`)),  // Map data to the table body
			startY: 30,  // Start position of the table (Y-axis)
			theme: 'grid',  // Theme with grid lines
			headStyles: {
				fillColor: [22, 160, 133],  // Light green background for header
				textColor: 255,  // White text for header
				fontStyle: 'bold',
				halign: 'center',  // Center-align header text
			},
			bodyStyles: {
				textColor: [44, 62, 80],  // Dark text color for content
				halign: 'center',  // Center-align body content
				valign: 'middle',  // Vertically align body content
			},
			alternateRowStyles: {
				fillColor: [245, 245, 245],  // Light gray for alternating rows
			},
			columnStyles: {
				// Auto width for columns based on content length
				0: { cellWidth: 'auto' },
				1: { cellWidth: 'auto' },
				2: { cellWidth: 'auto' },  // Adjust for more columns if needed
			},
			margin: { top: 30, left: 14, bottom: 14 },  // Set page margins
			pageBreak: 'auto',  // Automatically handle pagination
			didDrawPage: function (data) {
				// Add page number
				pageCount++;
				const pageNumber = `Page ${pageCount}`;
				doc.setFontSize(10);
				doc.text(pageNumber, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10, { align: 'right' });

				// Add "Next Page" text (This can be placed near the bottom of the page)
				if (pageCount < doc.internal.getNumberOfPages()) {
					const nextPageText = 'Next Page';
					doc.setFontSize(12);
					doc.setTextColor(150);  // Set gray color
					doc.text(nextPageText, doc.internal.pageSize.width - 50, doc.internal.pageSize.height - 30);
				}

				// Add watermark footer text on the last page
				if (pageCount === doc.internal.getNumberOfPages()) {
					const footerText = 'Credit to StoryMetrics';
					doc.setFontSize(8);
					doc.setTextColor(200, 200, 200); // Light gray color for watermark
					doc.text(footerText, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 5, { align: 'center' });
				}
			}
		});

		// Save the PDF
		doc.save('data.pdf');

		// Log the click event to analytics
		logEvent("click", "ChatAction", "download", "PDF");
	};

	const exportChartToPDF = async (chartTitle = "Chart Report") => {
		const doc = new jsPDF();

		// Select the chart container
		const chartContainer = document.querySelector(".highcharts-container");

		if (!chartContainer) {
			console.error("Chart not found!");
			return;
		}

		// Convert chart to image
		const chartCanvas = await html2canvas(chartContainer);
		const chartImage = chartCanvas.toDataURL("image/png");

		// Add title
		doc.setFont("Helvetica", "normal");
		doc.setFontSize(18);
		doc.text(chartTitle, 14, 20);

		// Add chart image to PDF
		doc.addImage(chartImage, "PNG", 14, 30, 180, 90); // Adjust position & size as needed

		// Save the PDF
		doc.save("chart.pdf");
	};

	// Function to export to CSV
	const handleDownloadCSV = () => {
		const csv = Papa.unparse(data);
		const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = 'data.csv';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		// Log the click event to analytics
		logEvent("click", "ChatAction", "download", "CSV");
	};

	// Function to export to Excel
	const handleDownloadExcel = () => {
		const ws = XLSX.utils.json_to_sheet(data);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = 'data.xlsx';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		// Log the click event to analytics
		logEvent("click", "ChatAction", "download", "Excel");
	};

	return (
		<div>
			<Menu
				menuClassName={`z-10 min-w-[13rem] bg-primary py-6 px-4 rounded shadow-primary-1`}
				menuButton={<div className="flex items-center gap-1">
					<img
						src={exportIcon}
						alt="Download"
						className={`h-6 w-6`}
					/>
					EXPORT
				</div>}
				transition
				align="center"
			>
				<MenuItem onClick={handleDownloadPDF}>Export as PDF (Table)</MenuItem>
				{/* <MenuItem onClick={exportChartToPDF}>Export as PDF (Graph)</MenuItem> */}
				<MenuItem onClick={handleDownloadCSV}>Export as CSV</MenuItem>
				<MenuItem onClick={handleDownloadExcel}>Export as Excel</MenuItem>
			</Menu>
		</div>
	);
};

export default ExportComponent;
