import styles from "styles/dialogue.module.css";
import Layout from "components/Layout";
import ChatWithDoc from "components/DocAnalysis/ChatWithDoc";

const ChatWithDocWindow = () => {
  return (
    <Layout>
      <div className={styles.newDialog}>
        <ChatWithDoc />
      </div>
    </Layout>
  );
};

export default ChatWithDocWindow;
