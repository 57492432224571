import axios from "axios";

class AxiosFactory {
    constructor(baseURL) {
        this.baseURL = baseURL;
    }

    createInstance() {
        const instance = axios.create({
            baseURL: this.baseURL,
        });

        // Add interceptors for the instance if needed
        instance.interceptors.request.use(
            (request) => {
                // Get the token from localStorage
                const token = localStorage.getItem("authToken");
                const tenantId = localStorage.getItem("tenantId");

                // If token exists, add it to the Authorization header
                if (token) {
                    request.headers["Authorization"] = `Bearer ${token}`;
                }

                // Add tenantId to headers
                if (tenantId) {
                    request.headers["tenant-app-id"] = tenantId;
                }

                // Always return the request to continue the request cycle
                return request;
            },
            (err) => {
                // If any error occurs in the request, reject the promise
                return Promise.reject(err);
            }
        );
        
        // Response interceptor
        instance.interceptors.response.use(
            (response) => {
                // Return the response as is
                return response;
            },
            (error) => {
                // Handle errors here (e.g., token expiration, network error, etc.)
                if (error.response && error.response.status === 401) {
                    // Handle unauthorized access (token expired or invalid)
                    console.log("Unauthorized access. Please login again.");
                }

                // Reject the promise with the error object
                return Promise.reject(error);
            }
        );

        return instance;
    }
}

export default AxiosFactory;