import ChatWindow from 'pages/chatWindow';
import ChatWindowAlt from 'pages/chatWindowAlt';
import HistoryWindow from 'pages/historyWindow';
import HistoryChatWindow from 'pages/historyChatWindow';
import AlertsWindow from 'pages/alertsWindow';
import DashboardWindow from 'pages/dashboardWindow';
import CollaborativeForumListWindow from 'pages/collaborativeForumListWindow';
import CollaborativeForumDetailWindow from 'pages/collaborativeForumDetailWindow';
import ChangePasswordPage from 'pages/user/changePasswordPage';
import ManageCredentialsPage from 'pages/admin/manageCredentialsPage';
import AnalyzeDocumentWindow from 'pages/chats/analyzeDocumentWindow';
import ChatWithDocWindow from 'pages/chats/chatWithDocWindow';

const protectedRoutes = [
    {
        path: "/chat-old",
        name: "Chat Page",
        element: <ChatWindow />,
    },
    {
        path: "/chat",
        name: "Chat Page",
        element: <ChatWindowAlt />,
    },
    {
        path: "/chat-history",
        name: "Chat History",
        element: <HistoryChatWindow />,
    },
    {
        path: "analyze-doc",
        name: "Analyze Document",
        element: <AnalyzeDocumentWindow />,
    },
    {
        path: "analyze-doc/:docId",
        name: "Chat with document",
        element: <ChatWithDocWindow />,
    },
    {
        path: "/history",
        name: "History",
        element: <HistoryWindow />,
    },
    {
        path: "/alerts",
        name: "Alerts",
        element: <AlertsWindow />,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        element: <DashboardWindow />
    },
    {
        path: "/collaborative-forum",
        name: "Collaborative Forum Listing",
        element: <CollaborativeForumListWindow />
    },
    {
        path: "/collaborative-forum-detail",
        name: "Collaborative Forum Detail",
        element: <CollaborativeForumDetailWindow />
    },
    {
        path: "/change-password",
        name: "Change Password",
        element: <ChangePasswordPage />,
    },
    {
        path: "/manage-credentials",
        name: "Manage Credentials",
        element: <ManageCredentialsPage />,
    }
]

export default protectedRoutes;